<template>
    <Form ref="appform" v-slot="{ errors }" tag="form">
        <div class="page-container">
            <div class="page" :aria-hidden="modalShown">

                <div class="error-summary" v-if="showErrorSummary" id="error-summary" :tabindex="modalShown ? -1 : 0">
                    <h2>There's some problems with the information you've submitted below.</h2>
                    <p>
                        Please correct these before you continue;
                    </p>
                    <ul>
                        <template v-if="Object.keys(errors).length">
                            <li v-for="(message, field) in errors" :key="field">
                                <span @click.prevent="scrollInput(field)" class="summary-link" :tabindex="modalShown ? -1 : 0" role="link">{{ message }}</span>
                            </li>
                        </template>
                    </ul>
                </div>

                <h2 tabindex="-1" class="main-anchor">Your details</h2>

                <p>To check your eligibility for finance, please fill out the following details.</p>

                <Field rules="required" v-slot="{ field, meta }" name="title" v-model="title">
                    <div :class="{'form-row':true, 'is-valid': !errors.title && meta.touched}">
                        <div class="form-row-label">
                            <label for="title" id="titleLabel">Title <i>(Required)</i></label>
                        </div>

                        <div class="form-row-control">
                            <select id="title"
                                    v-bind="field"
                                    name="title"
                                    autocomplete="honorific-prefix"
                                    vid="title"
                                    aria-describedby="titleErrors"
                                    :tabindex="modalShown ? -1 : 0"
                                    :aria-invalid="errors.title ? true : false"
                                    @change="setFocus($event)"
                                    :class="{'invalid': errors.title, 'valid': !errors.title && meta.touched}">
                                <option value selected disabled>Please select one</option>
                                <option v-for="(item, key) in enums.titleEnums"
                                        :value="item.value"
                                        v-bind:key="key">
                                    {{item.key}}
                                </option>
                            </select>
                        </div>

                        <span :class="{'invalid-text': errors.title, 'form-row-validation':true }" v-if="errors.title" id="titleErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.title }}</span>
                        </span>
                    </div>
                </Field>

                <Field rules="required|min:2|max:15|start_with_letter|name" v-model="application.firstName" name="firstName" v-slot="{ field, meta }">
                    <div :class="{'form-row':true, 'is-valid': !errors.firstName && meta.touched}">
                        <div class="form-row-label">
                            <label for="firstName" id="firstNameLabel">First name <i>(Required)</i></label>
                        </div>

                        <div class="form-row-control">
                            <input id="firstName"
                                   v-bind="field"
                                   name="firstName"
                                   type="text"
                                   :value="firstName"
                                   @input="value => firstName = value.currentTarget.value"
                                   autocomplete="given-name"
                                   aria-describedby="firstNameSupportText firstNameErrors"
                                   :tabindex="modalShown ? -1 : 0"
                                   :aria-invalid="errors.firstName ? true : false"
                                   :class="{'invalid': errors.firstName, 'valid': !errors.firstName && meta.touched}" />
                        </div>

                        <span class="support-text" id="firstNameSupportText">This must be your legal first name in full</span>

                        <span :class="{'invalid-text': errors.firstName, 'form-row-validation':true }" v-if="errors.firstName" id="firstNameErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.firstName }}</span>
                        </span>

                    </div>
                </Field>

                <Field rules="min:2|max:15|start_with_letter|name" name="middleName" v-slot="{ field, meta }">
                    <div :class="{'form-row':true, 'is-valid': !errors.middleName && (meta.touched || submitButtonPressed)}">
                        <div class="form-row-label">
                            <label for="middleName" id="middleNameLabel">Middle name <i>(Optional)</i></label>
                        </div>

                        <div class="form-row-control">
                            <input id="middleName"
                                   v-bind="field"
                                   name="middleName"
                                   type="text"
                                   :value="middleName"
                                   @input="value => middleName = value.currentTarget.value"
                                   autocomplete="additional-name"
                                   aria-describedby="middleNameErrors"
                                   :tabindex="modalShown ? -1 : 0"
                                   :aria-invalid="errors.middleName ? true : false"
                                   :class="{'invalid': errors.middleName, 'valid': !errors.middleName && meta.touched}" />
                        </div>

                        <span :class="{'invalid-text': errors.middleName, 'form-row-validation':true }" v-if="errors.middleName" id="middleNameErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.middleName }}</span>
                        </span>

                    </div>
                </Field>

                <Field rules="required|min:2|max:30|start_with_letter|last_name" v-model="application.lastName" name="lastName" v-slot="{ field, meta }">
                    <div :class="{'form-row':true, 'is-valid': !errors.lastName && meta.touched}">

                        <div class="form-row-label">
                            <label for="lastName" id="lastNameLabel">Last name <i>(Required)</i></label>
                        </div>

                        <div class="form-row-control">
                            <input id="lastName"
                                   v-bind="field"
                                   name="lastName"
                                   type="text"
                                   :value="lastName"
                                   @input="value => lastName = value.currentTarget.value"
                                   autocomplete="family-name"
                                   aria-describedby="lastNameSupportText lastNameErrors"
                                   :tabindex="modalShown ? -1 : 0"
                                   :aria-invalid="errors.lastName ? true : false"
                                   :class="{'invalid': errors.lastName, 'valid': !errors.lastName && meta.dirty}" />
                        </div>

                        <span class="support-text" id="lastNameSupportText">This must be your legal last name in full</span>

                        <span :class="{'invalid-text': errors.lastName, 'form-row-validation':true }" v-if="errors.lastName" id="lastNameErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.lastName }}</span>
                        </span>

                    </div>
                </Field>

                <Field rules="required_date|date|date_of_birth|date_of_birth_overage" name="dateOfBirth" v-model="application.dateOfBirth" v-slot="{ field, meta }">
                    <div :class="{'form-row':true, 'is-valid': !errors.dateOfBirth && meta.touched}">
                        <date-input v-bind="field"
                                    :value="application.dateOfBirth"
                                    :hasErrors="!!errors.dateOfBirth"
                                    :isValid="meta.valid">
                        </date-input>

                        <span :class="{'invalid-text': errors.dateOfBirth, 'form-row-validation':true }" v-if="errors.dateOfBirth" id="dateOfBirthErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.dateOfBirth }}</span>
                        </span>
                    </div>
                </Field>

                <Field rules="required" name="employmentStatus" v-model="employmentStatus" v-slot="{ field, meta }">
                    <div :class="{'form-row':true, 'is-valid': !errors.employmentStatus && meta.touched}">

                        <div class="form-row-label">
                            <label for="employmentStatus" id="employmentStatusLabel">Employment status <i>(Required)</i></label>
                        </div>

                        <div class="form-row-control">
                            <select id="employmentStatus"
                                    v-bind="field"
                                    name="employmentStatus"
                                    aria-describedby="employmentStatusErrors"
                                    :tabindex="modalShown ? -1 : 0"
                                    :aria-invalid="errors.employmentStatus ? true : false"
                                    @change="setFocus($event)"
                                    :class="{'invalid': errors.employmentStatus, 'valid': !errors.employmentStatus && meta.touched}">
                                <option value selected disabled>Please select one</option>
                                <option v-for="(item, key) in enums.employmentStatusEnums"
                                        :value="item.value"
                                        v-bind:key="key">
                                    {{item.key}}
                                </option>
                            </select>
                        </div>

                        <span :class="{'invalid-text': errors.employmentStatus, 'form-row-validation':true }" v-if="errors.employmentStatus" id="employmentStatusErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.employmentStatus }}</span>
                        </span>

                    </div>
                </Field>

                <Field rules="required|max_int_value:10000000" name="grossAnnualIncome" v-model="application.grossAnnualIncome" v-slot="{ field, meta }">
                    <div :class="{'form-row':true, 'is-valid': !errors.grossAnnualIncome && meta.touched}">

                        <div class="form-row-label" id="grossAnnualIncome-labelRow">
                            <label for="grossAnnualIncome" id="grossAnnualIncomeLabel">Gross annual income <i>(Required)</i></label>

                            <tooltip tooltipId="grossAnnualIncomeTooltip"
                                     tooltipTextId="grossAnnualIncomeTooltipText"
                                     labeledBy="grossAnnualIncomeLabel tooltip">
                                Gross annual income is the total amount you earn in a year before tax. Pension income, dividends, and/or disability allowance can be included in this figure. Income support and Jobseeker's Allowance are not accepted.
                            </tooltip>

                        </div>
                        <div class="form-row-control income-padded" id="grossAnnualIncomeContainer">
                            <currency-input id="grossAnnualIncome"
                                            name="grossAnnualIncome"
                                            v-bind="field"
                                            :value="application.grossAnnualIncome"
                                            maxLength="7"
                                            aria-labelledby="grossAnnualIncomeLabel"
                                            aria-describedby="grossAnnualIncomeErrors grossAnnualIncomeTooltipText"
                                            :tabindex="modalShown ? -1 : 0"
                                            :aria-invalid="errors.grossAnnualIncome ? true : false"
                                            :isValid="meta.valid"
                                            :hasErrors="errors.grossAnnualIncome"
                                            aria-expanded="true" />
                        </div>

                        <span :class="{'invalid-text': errors.grossAnnualIncome, 'form-row-validation':true }" v-if="errors.grossAnnualIncome" id="grossAnnualIncomeErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.grossAnnualIncome }}</span>
                        </span>

                    </div>
                </Field>

                <template v-if="retailer.displayAffordabilityQuestions == true">
                    <template v-for="component in retailer.content.AffordabilityQuestionOrder.split(',')" :key="component">
                        <template v-if="component == 'monthly-spend-question'">
                            <Field rules="required" v-model="affordability.spendToIncrease" name="spendToIncrease">
                                <div class="form-row radio" id="spendToIncrease">

                                    <div class="form-row-label">
                                        <label class="form-row-label" for="spendToIncrease" id="spendToIncreaseLabel" v-html="retailer.content.SpendToIncreaseQuestion"></label>

                                        <tooltip tooltipId="spendToIncreaseTooltip"
                                                 tooltipTextId="spendToIncreaseTooltipText"
                                                 labeledBy="spendToIncreaseLabel tooltip">
                                            <span v-html="retailer.content.SpendToIncreaseTooltip"></span>

                                        </tooltip>
                                    </div>
                                    <fieldset role="radiogroup"
                                              aria-labelledby="spendToIncreaseLabel">

                                        <label class="radio-item">
                                            <input id="spendToIncreaseYes"
                                                   type="radio"
                                                   value='true'
                                                   v-model="affordability.spendToIncrease"
                                                   name="spendToIncreaseRadio"
                                                   :tabindex="modalShown ? -1 : 0"
                                                   area-expanded="true"
                                                   aria-describedby="spendToIncreaseErrors" />
                                            <span class="radio-span" id="spendToIncreaseYesLabel" />Yes
                                        </label>
                                        <label class="radio-item">
                                            <input id="spendToIncreaseNo"
                                                   type="radio"
                                                   value='false'
                                                   v-model="affordability.spendToIncrease"
                                                   name="spendToIncreaseRadio"
                                                   :tabindex="modalShown ? -1 : 0"
                                                   area-expanded="true"
                                                   aria-describedby="spendToIncreaseErrors" />
                                            <span class="radio-span" id="spendToIncreaseNoLabel" />No

                                        </label>
                                    </fieldset>
                                </div>
                            </Field>
                            <span style="margin-bottom: 30px; margin-top: -1rem;" :class="{'invalid-text': errors.spendToIncrease, 'form-row-validation':true }" v-if="errors.spendToIncrease" id="spendToIncreaseErrors" aria-live="polite">
                                <span class="error-bold">Error: </span><span>{{ errors.spendToIncrease }}</span>
                            </span>


                            <Field v-if="affordability.spendToIncrease=='true'" rules="required|max_int_value:10000000" name="monthlySpendIncrease" v-model="affordability.monthlySpendIncrease" v-slot="{ field, meta }">
                                <div :class="{'form-row':true, 'is-valid': !errors.monthlySpendIncrease && meta.touched}">

                                    <div class="form-row-label">
                                        <label for="monthlySpendIncrease" id="monthlySpendIncreaseLabel" v-html="retailer.content.SpendToIncreaseFollowUp"></label>
                                    </div>

                                    <div class="form-row-control income-padded">
                                        <currency-input id="monthlySpendIncrease"
                                                        name="monthlySpendIncrease"
                                                        v-bind="field"
                                                        :value="affordability.monthlySpendIncrease"
                                                        maxLength="7"
                                                        :tabindex="modalShown ? -1 : 0"
                                                        aria-describedby="monthlySpendIncreaseErrors"
                                                        :isValid="meta.valid"
                                                        :hasErrors="errors.monthlySpendIncrease"
                                                        :aria-invalid="errors.monthlySpendIncrease ? true : false" />
                                    </div>
                                </div>
                            </Field>
                            <span style="margin-bottom: 30px; margin-top: -1rem;" :class="{'invalid-text': errors.monthlySpendIncrease, 'form-row-validation':true }" v-if="errors.monthlySpendIncrease" id="monthlySpendIncreaseErrors" aria-live="polite">
                                <span class="error-bold">Error: </span><span>{{ errors.monthlySpendIncrease }}</span>
                            </span>
                        </template>

                        <template v-else-if="component == 'dependants-num-question'">
                            <Field rules="required" v-model="dependantsCheck" name="dependantsCheck" v-if="retailer.content.PrefixNumDependants==true">
                                <div class="form-row radio" id="dependantsCheck" >

                                    <div class="form-row-label">
                                        <label class="form-row-label" for="dependantsCheck" id="dependantsCheckLabel">
                                            Do you have any financial dependants? <i>(Required)</i>
                                        </label>

                                        <tooltip tooltipId="dependantsCheckTooltip"
                                                 tooltipTextId="dependantsCheckTooltipText"
                                                 labeledBy="dependantsCheckLabel tooltip">
                                            A dependant is someone who relies on you for financial support, such as for essential living costs or care costs.<br>This could be your children, spouse or partner and ex-spouse or partner and other relatives.
                                        </tooltip>
                                    </div>
                                    <fieldset role="radiogroup"
                                              aria-labelledby="dependantsCheckLabel">

                                        <label class="radio-item">
                                            <input id="dependantsCheckYes"
                                                   type="radio"
                                                   value='true'
                                                   v-model="dependantsCheck"
                                                   name="dependantsCheckRadio"
                                                   :tabindex="modalShown ? -1 : 0"
                                                   area-expanded="true"
                                                   aria-describedby="dependantsCheckErrors" />
                                            <span class="radio-span" id="dependantsCheckYesLabel" />Yes
                                        </label>
                                        <label class="radio-item">
                                            <input id="dependantsCheckNo"
                                                   type="radio"
                                                   value='false'
                                                   v-model="dependantsCheck"
                                                   name="dependantsCheckRadio"
                                                   :tabindex="modalShown ? -1 : 0"
                                                   area-expanded="true"
                                                   aria-describedby="dependantsCheckErrors" />
                                            <span class="radio-span" id="dependantsCheckNoLabel" />No

                                        </label>
                                    </fieldset>
                                </div>
                                <span style="margin-bottom: 30px; margin-top: -1rem;" :class="{'invalid-text': errors.dependantsCheck, 'form-row-validation':true }" v-if="errors.dependantsCheck" id="dependantsCheckError" aria-live="polite">
                                    <span class="error-bold">Error: </span><span>{{ errors.dependantsCheck }}</span>
                                </span>
                            </Field>
                            
                            <Field v-if="retailer.displayDependantsQuestion == true && (retailer.content.PrefixNumDependants==false || affordability.dependantsCheck=='true')" rules="required" v-model="dependants" name="dependants" v-slot="{ meta, setTouched }">
                                <dependants-num-question @click="setTouched(true)" :isValid="meta.valid" :hasErrors="errors.dependants" />
                                <span style="margin-bottom: 30px; margin-top: -1rem;" :class="{'invalid-text': errors.dependants, 'form-row-validation':true }" v-if="errors.dependants" id="dependantsError" aria-live="polite">
                                    <span class="error-bold">Error: </span><span>{{ errors.dependants }}</span>
                                </span>
                            </Field>
                        </template>

                        <template v-else-if="component == 'dependants-cost-question' && (retailer.content.PrefixNumDependants==false || affordability.dependantsCheck=='true')">
                            <Field rules="required" v-model="affordability.childcareCost" name="childcareCost" v-slot="{ field, meta }">
                                <div :class="{'form-row':true, 'is-valid': !errors.childcareCost && meta.touched}">

                                    <div class="form-row-label" id="childcareCost-labelRow">
                                        <label for="childcareCost" id="childcareCostLabel" v-html="retailer.content.DependantsCostQuestion"></label>

                                        <tooltip tooltipId="childcareCostTooltip"
                                                 tooltipTextId="childcareCostTooltipText"
                                                 labeledBy="childcareCostLabel tooltip">
                                            <span v-html="retailer.content.DependantsCostTooltip"></span>
                                        </tooltip>

                                    </div>

                                    <div class="form-row-control income-padded">
                                        <currency-input id="childcareCost"
                                                        name="childcareCost"
                                                        v-bind="field"
                                                        :value="affordability.childcareCost"
                                                        maxLength="7"
                                                        :tabindex="modalShown ? -1 : 0"
                                                        aria-describedby="childcareCostErrors"
                                                        :isValid="meta.valid"
                                                        :hasErrors="errors.childcareCost"
                                                        :aria-invalid="errors.childcareCost ? true : false" />
                                    </div>
                                </div>
                            </Field>
                            <span style="margin-bottom: 30px; margin-top: -1rem;" :class="{'invalid-text': errors.childcareCost, 'form-row-validation':true }" v-if="errors.childcareCost" id="childcareCostErrors" aria-live="polite">
                                <span class="error-bold">Error: </span><span>{{ errors.childcareCost }}</span>
                            </span>
                        </template>

                        <template v-else-if="component == 'income-decrease-question'">
                            <Field rules="required" v-model="incomeToDecrease" name="incomeToDecrease">
                                <div class="form-row radio" id="incomeToDecrease">

                                    <div class="form-row-label">
                                        <label for="incomeToDecrease" id="incomeToDecreaseLabel" v-html="retailer.content.IncomeDecreaseQuestion"></label>

                                        <tooltip labeledBy="incomeToDecreaseLabel tooltip"
                                                 tooltipId="incomeToDecreaseTooltip"
                                                 tooltipTextId="incomeToDecreaseTooltipText">
                                            <span v-html="retailer.content.IncomeDecreaseTooltip"></span>
                                        </tooltip>
                                    </div>
                                    <fieldset role="radiogroup"
                                              aria-labelledby="incomeToDecreaseLabel">
                                        <label class="radio-item">
                                            <input id="incomeToDecreaseYes"
                                                   type="radio"
                                                   value='true'
                                                   v-model="incomeToDecrease"
                                                   name="incomeToDecreaseRadio"
                                                   :tabindex="modalShown ? -1 : 0"
                                                   area-expanded="true"
                                                   aria-describedby="incomeToDecreaseErrors" />
                                            <span class="radio-span" id="incomeToDecreaseYesLabel" />Yes
                                        </label>
                                        <label class="radio-item">
                                            <input id="incomeToDecreaseNo"
                                                   type="radio"
                                                   value='false'
                                                   v-model="incomeToDecrease"
                                                   name="incomeToDecreaseRadio"
                                                   :tabindex="modalShown ? -1 : 0"
                                                   aria-describedby="incomeToDecreaseErrors" />
                                            <span class="radio-span" id="incomeToDecreaseNoLabel" />No
                                        </label>
                                    </fieldset>
                                </div>
                            </Field>
                            <span style="margin-bottom: 30px; margin-top: -1rem; " :class="{'invalid-text': errors.incomeToDecrease, 'form-row-validation':true }" v-if="errors.incomeToDecrease" id="dependantsCheckError" aria-live="polite">
                                <span class="error-bold">Error: </span><span>{{ errors.incomeToDecrease }}</span>
                            </span>

                            <Field v-if="affordability.incomeToDecrease=='true'" rules="required|max_int_value:@grossAnnualIncome" v-model="affordability.newGrossAnnualIncome" name="newGrossAnnualIncome" v-slot="{ field, meta }">
                                <div :class="{'form-row':true, 'is-valid': !errors.newGrossAnnualIncome && meta.touched}">

                                    <div class="form-row-label">
                                        <label for="newGrossAnnualIncome" id="newGrossAnnualIncomeLabel" v-html="retailer.content.IncomeDecreaseFollowUp"></label>
                                    </div>

                                    <div class="form-row-control income-padded">
                                        <currency-input id="newGrossAnnualIncome"
                                                        name="newGrossAnnualIncome"
                                                        v-bind="field"
                                                        :value="affordability.newGrossAnnualIncome"
                                                        maxLength="7"
                                                        :tabindex="modalShown ? -1 : 0"
                                                        aria-describedby="newGrossAnnualIncomeErrors"
                                                        :isValid="meta.valid"
                                                        :hasErrors="errors.newGrossAnnualIncome"
                                                        :aria-invalid="errors.newGrossAnnualIncome ? true : false" />


                                    </div>

                                </div>
                            </Field>
                            <span style="margin-bottom: 30px; margin-top: -1rem;" :class="{'invalid-text': errors.newGrossAnnualIncome, 'form-row-validation':true }" v-if="errors.newGrossAnnualIncome && incomeToDecrease == 'true'" id="newGrossAnnualIncomeErrors" aria-live="polite">
                                <span class="error-bold">Error: </span><span>{{ errors.newGrossAnnualIncome }}</span>
                            </span>
                        </template>
                    </template>
                </template>

                <Field rules="required" name="residentialStatus" v-model="residentialStatus" v-slot="{ field, meta }">
                    <div :class="{'form-row':true, 'is-valid': !errors.residentialStatus && meta.touched}">

                        <div class="form-row-label">
                            <label for="residentialStatus" id="residentialStatusLabel">Residential status <i>(Required)</i></label>
                        </div>

                        <div class="form-row-control">
                            <select id="residentialStatus"
                                    v-bind="field"
                                    name="residentialStatus"
                                    aria-describedby="residentialStatusErrors"
                                    :tabindex="modalShown ? -1 : 0"
                                    :aria-invalid="errors.residentialStatus ? true : false"
                                    @change="setFocus($event)"
                                    :class="{'invalid': errors.residentialStatus, 'valid': !errors.residentialStatus && meta.dirty}">
                                <option value selected disabled>Please select one</option>
                                <option v-for="(item, key) in enums.residentialStatusEnums"
                                        :value="item.value"
                                        v-bind:key="key">
                                    {{item.key}}
                                </option>
                            </select>
                        </div>

                        <span :class="{'invalid-text': errors.residentialStatus, 'form-row-validation':true }" v-if="errors.residentialStatus" id="residentialStatusErrors" aria-live="polite">
                            <span class="error-bold">Error: </span><span>{{ errors.residentialStatus }}</span>
                        </span>

                    </div>
                </Field>

                <h3>Address details</h3>

                <p>Please provide your UK addresses from the last three years.</p>

                    <div class="qas-area">
                        <address-area v-for="(address, index) in addresses"
                                      :key="address.id"
                                      :address="address"
                                      :addressIndex="index"
                                      :previousAddress="addresses[index-1]"
                                      :maxAddresses="3"
                                      @updateAddressTemplate="updateAddressTemplate"
                                      @deleteAddress="deleteAddress"
                                      @deleteAddressesAfterIndex="deleteAddressesAfterIndex"
                                      ref="addresses"></address-area>
                    </div>

                <hr aria-hidden="true" />

                <p>
                    By clicking 'Check my eligibility', you agree to our <span id="termsAndConditionsButton" class="terms-and-conditions-link" @click.prevent="showTermsAndConditions" @keydown.enter.prevent="showTermsAndConditions" :tabindex="modalShown ? -1 : 0">terms and conditions.</span> Your details will be checked
                    with credit reference agencies, but this will not affect your credit score. For further information,  read our <span id="privacyPolicyButton" class="privacy-policy-link" @click.prevent="showPrivacyPolicy" @keydown.enter.prevent="showPrivacyPolicy" :tabindex="modalShown ? -1 : 0" role="button">privacy policy.</span>
                </p>

                <p>
                    By clicking 'Check my eligibility', You agree your details will be checked with credit reference agencies, but this will not affect your credit score. For further information, read our <span id="privacyPolicyButton" class="privacy-policy-link" @click.prevent="showPrivacyPolicy" @keydown.enter.prevent="showPrivacyPolicy" :tabindex="modalShown ? -1 : 0" role="button">privacy policy.</span>
                </p>

                <p>
                    For further information about the use of this website, please see our <a class="accessibility-link" href="https://www.barclayspartnerfinance.com/home/important-information/" target="_blank" :tabindex="modalShown ? -1 : 0">important information  <img src="../../assets/external-link-icon.svg" alt="opens in new window icon" /></a>.
                </p>

                <p>
                    Credit is subject to application, financial circumstances and borrowing history.
                </p>

                <div class="button-container">
                    <div class="button-container-inner float-left">
                        <router-link :to="'/?retailerCode=' + retailer.retailerCode" id="backButton" class="button secondary-button-text" role="button" :tabindex="modalShown ? -1 : 0">
                            Back
                        </router-link>
                    </div>

                    <div class="button-container-inner float-right">
                        <button type="submit" class="button primary-button-text float-right" :tabindex="modalShown ? -1 : 0" id="continueButton" @click.prevent="handleSubmit" @click="submitButtonPressed=true">Check my eligibility</button>
                    </div>
                </div>
            </div>

            <loading v-if="showLoading" ref="loadingScreen" :finishedLoading="finishedLoading" @endLoading="endLoading"></loading>

            <terms-and-conditions v-if="modals.termsAndConditionsShown" @closeModal="closeModal"></terms-and-conditions>

        </div>
        <span id="tooltip">Tooltip</span>
    </Form>

</template>

<script>

    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
    import DateInput from '../DateInput.vue'
    import Address from '../Address.vue'
    import TermsAndConditions from '../TermsAndConditions.vue'
    import LoadingScreen from '../Loading.vue'
    import CurrencyInput from '../CurrencyInput'
    import Tooltip from '../Tooltip'
    import moment from 'moment'
    import { Form, Field, configure, defineRule } from 'vee-validate'
    import { min, max, required } from '@vee-validate/rules'
    import { localize } from '@vee-validate/i18n'
    import DependantsNumQuestion from "../DependantsNumQuestion"

    defineRule('min', min);
    defineRule('max', max);
    defineRule('required', required);

    function parseCurrency(val) {
        if (val === '') return null

        if (typeof val == 'string') return parseInt(val.replace(/[^0-9]+/g, ''))

        return val
    }

    const parser = new DOMParser();

    function getPoundSign() {
        return parser.parseFromString('&pound;', 'text/html').children[0].innerText
    }

    configure({
        validateOnInput: true,
        generateMessage: localize('en', {
            fields: {
                title: {
                    required: `Please select a title from the list.`
                },
                firstName: {
                    start_with_letter: `Please enter your first name in full, beginning with a letter.`,
                    required: `Please enter your full legal first name.`,
                    min: `Please enter your first name in full. A single character is not permitted.`,
                    max: `Your first name must contain no more than 15 characters.`,
                    name: `Please enter your first name in full. You can use letters, a space and a hyphen.`
                },
                middleName: {
                    min: `Please enter your middle name in full. A single character is not permitted.`,
                    max: `Your middle name must contain no more than 15 characters.`,
                    start_with_letter: `Please enter your middle name in full, beginning with a letter.`,
                    name: `Please enter your middle name in full. You can use letters, a space and a hyphen.`
                },
                lastName: {
                    required: `Please enter your full legal last name.`,
                    min: `Please enter your last name in full. A single character is not permitted.`,
                    max: `Your last name must contain no more than 30 characters.`,
                    start_with_letter: `Please enter your last name in full, beginning with a letter.`,
                    alpha_special_apos: `Please enter your last name in full. You can use letters, a space, a hyphen and an apostrophe.`,
                    name: `Please enter your last name in full. You can use letters, a space, a hyphen and an apostrophe.`,
                    lastName: `You've entered an invalid last name. Please check and try again.`
                },
                dateOfBirth: {
                    required_date: `Please enter your date of birth.`,
                    date_of_birth: `Sorry - You must be at least 18 years old to apply for this product.`,
                    date_of_birth_overage: `You have entered an invalid date of birth. Please try again, using this format: DD MM YYYY.`,
                    date: `You have entered an invalid date of birth. Please try again, using this format: DD MM YYYY.`
                },
                employmentStatus: {
                    required: `Please choose your employment status from the list.`
                },
                grossAnnualIncome: {
                    required: `Please enter your full annual income before tax, using numbers. For example: ${getPoundSign()}25000.`,
                    max_int_value: `Please enter a value less than ${getPoundSign()}10,000,000`
                },
                residentialStatus: {
                    required: `Please choose your residential status from the list.`
                },
                monthlySpendIncrease: {
                    required: `Please enter your expected monthly spend increase. For example: ${getPoundSign()}250.`,
                    max_int_value: `Please enter a value less than ${getPoundSign()}10,000,000`
                },
                dependantsCheck: {
                    required: `Please select whether you have any financial dependants.`
                },
                spendToIncrease: {
                    required: `Please select whether your monthly spend is expected to increase.`
                },
                childcareCost: {
                    required: `Please enter your monthly childcare costs. For example: ${getPoundSign()}250.`,
                    max_int_value: `Please enter a value less than ${getPoundSign()}10,000,000`
                },
                dependants: {
                    required: `Please select your number of dependants.`
                },
                incomeToDecrease: {
                    required: `Please select whether your income is due to decrease.`
                },
                newGrossAnnualIncome: {
                    required: `Please enter your expected new annual income before tax, using numbers. For example: ${getPoundSign()}25000.`,
                    max_int_value: `Please enter a value less than your gross annual income`
                },
            },
        }),
    })

    defineRule('postcode', value => {
        var pattern = new RegExp("^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {0,1}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$")

        return pattern.test(value.toUpperCase())
    })

    defineRule('start_with_letter', value => {
        if (value) {
        var pattern = new RegExp('^[a-zA-Z]')
            return pattern.test(value);
        }

            return true;
    });

    defineRule('name', (value) => {
        if (value) {
        var pattern = new RegExp('^[a-zA-Z]+?( ?[a-zA-z]*-?|-?[a-zA-Z]* ?)[a-zA-Z]*$');
            return pattern.test(value);
        }

            return true;
    });

    defineRule('last_name', value => {
        var msg = `You've entered an invalid last name. Please check and try again.`
        //alpha_special_apos first
        var pattern = new RegExp(`^(?!.*'.*')(?!.* .* )(?!.*-.*-)[a-zA-Z-' ]+$`)
        if (!pattern.test(value)) {
            return msg;
        }

        //check for single space / hyphen after first char
        pattern = new RegExp(`^(?!.*'.*')[a-zA-Z]'?[^']+$`)
        if (!pattern.test(value)) {
            return msg;
        }

        return true;
    });

    defineRule('required_date', value => {
        if (!value) {
            return false;
        }

        var val = value.toString();
        let valid = false;

        if (value) {
            let dateParts = val.split('-');
            if (dateParts[0] != '' && dateParts[1] != '' && dateParts[2] != '') {
                if (typeof dateParts[2] !== "undefined" && dateParts[2].length > 3) {
                    valid = true
                }
            }
        }

        return valid;
    });

    defineRule('date_of_birth', value => {

        const momentToday = moment()
        let birthDate = moment(value, 'DD-MM-YYYY')

        //let this one fall through to overage
        if (birthDate > momentToday || !birthDate.isValid()) {
            return true;
        }

        var today = new Date();
        var age = today.getFullYear() - birthDate.year();
        var m = today.getMonth() - birthDate.month();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.date())) {
            age--;
        }

        return age >= 18;
    });

    defineRule('date', value => {
        if (!value) {
            return false;
        }

        var stringValue = value.toString();

        let dateParts = stringValue.split('-');

        if (dateParts[0] == '' || dateParts[1] == '' || dateParts[2] == '') return true;


        let formattedDate = moment(value, 'DD-MM-YYYY');

        if (formattedDate.month() > 12) {
            return false;
        }
        if (formattedDate.date() > 31) {
            return false;
        }
        if (formattedDate.year() < 1900) {
            return false;
        }

        return moment(value, 'DD-MM-YYYY').isValid();
    });

    defineRule('date_of_birth_overage', value => {

        //let this fall through
        if (!value) {
            return true;
        }

        const today = moment();
        const birthDate = moment(value, 'DD-MM-YYYY')

        var age = today.subtract(1, 'days').diff(birthDate, 'years');

        if (age >= 111 || age < 0) {
            return false;
        }

        if (birthDate > today) {
            return false;
        }

        return true;
    });

    defineRule('max_int_value', (value, [maxValue]) => {
        let currentValue = typeof value == 'string' ? value.replace(/,/g, '') : value;
        let currentMaxValue = typeof maxValue == 'string' ? maxValue.replace(/,/g, '') : maxValue;

        return parseInt(currentValue) < parseInt(currentMaxValue);
    });

    defineRule('alpha_special_unlimited', (value) => {
        if (value) {
            var pattern = new RegExp('^[0-9a-zA-Z-\' *./]+$')
            return pattern.test(value)
        }

        return true
    });

    defineRule('start_with_number', (value) => {
        if (value) {
            var pattern = new RegExp('^[0-9]')
            return pattern.test(value)
        }

        return true
    });

    defineRule('one_of_required', (value, [target]) => {
        if (!value && !target) {
            return false;
        }
        return true;
    });

    defineRule('only_numbers', (value) => {
        var pattern = new RegExp('^[0-9]+$')
        return !pattern.test(value);
    });

    defineRule('start_with_letter_or_number', (value) => {
        if (value) {
            var pattern = new RegExp('^[A-z0-9]')
            return pattern.test(value);
        }
        return true;
    });

    defineRule('alpha_special_unlimited_ampersand', (value) => {
        var pattern = new RegExp('^[0-9a-zA-Z-\' *&./]+$')
        return pattern.test(value);
    })

    defineRule('at_least_two_alpha', (value) => {
        var pattern = new RegExp('(.*[a-zA-Z]){2}')
        return pattern.test(value);
    })

    defineRule('not_in_future', (value) => {
        if (value === '' || value === undefined || value === null) return true;

        let today = moment();
        let dateValue = moment(value, 'DD-MM-YYYY');

        if (today < dateValue) return false;
        return true;
    });

    defineRule('date_before', (value, [target]) => {

        if (!value || !target) {
            return true;
        }
        var date = moment(value, 'DD-MM-YYYY');
        var previousDate = moment(target, 'DD-MM-YYYY');
        if (date >= previousDate) return false;
        return true;
    })

    defineRule('less_than_years', (value) => {
        if (!value) return true;

        let formattedDate = moment(value, 'DD-MM-YYYY');
        let today = moment();
        if (today.year() - formattedDate.year() > 111) {
            return false;
        }

        return true;
    })


    defineRule('valid_month', (value) => {
        if (!value) return true;
        let months = value.split('-')[1];
        if (!months) return true;
        if (months <= 0 || months > 12) return false;
        return true;
    })

    defineRule('valid_year', (value) => {
        if (!value) return true;
        let years = value.split('-')[2];
        if (!years) return true;
        if ((years && years.length < 4) || parseInt(years) < 1000) {
            return false;
        }
        return true;
    })

    export default {
        computed: {
            ...mapGetters(['enums', 'application', 'affordability', 'retailer', 'modals', 'modalShown', 'validationErrors']),
            ...mapFields([
                'application.title',
                'application.firstName',
                'application.middleName',
                'application.lastName',
                'application.dateOfBirth',
                'application.employmentStatus',
                'application.grossAnnualIncome',
                'application.residentialStatus',
                'affordability.incomeToDecrease',
                'affordability.spendToIncrease',
                'affordability.newGrossAnnualIncome',
                'affordability.monthlySpendIncrease',
                'affordability.childcareCost',
                'affordability.dependants',
                'affordability.dependantsCheck',
            ]),
            ...mapMultiRowFields([
                'application.addresses'
            ]),
            canSubmit: function () {
                let lastAddress = this.application.addresses[this.application.addresses.length - 1];

                //don't really want to duplicate this logic here
                if (!lastAddress.dateMovedToAddress) {
                    return false;
                }
                else {
                    let date = moment(lastAddress.dateMovedToAddress);

                    let maxDate = moment().subtract(3, 'years');

                    return date < maxDate;
                }
            }
        },
        components: {
            'date-input': DateInput,
            'address-area': Address,
            'terms-and-conditions': TermsAndConditions,
            'loading': LoadingScreen,
            'currency-input': CurrencyInput,
            'tooltip': Tooltip,
            Form,
            Field,
            'dependants-num-question': DependantsNumQuestion
        },
        data() {
            return {
                showLoading: false,
                finishedLoading: false,
                showErrorSummary: false,
                formErrors: [],
                isTouchScreen: false,
                searchingAddress: false,
                showPostcodeError: false,
                submitButtonPressed: false
            };
        },
        methods: {
            ...mapActions(['getConfig', 'submitApplication', 'closeModal']),
            ...mapMutations(['ADD_ADDRESS', 'REMOVE_ADDRESS', 'REMOVE_ADDRESSES_AFTER_INDEX', 'RESET_APPLICATION', 'SET_LAST_ELEMENT', 'CLEAR_VALIDATION_ERRORS', 'INITIALISE_AFFORDABILITY']),
            showTermsAndConditions: function () {
                this.modals.termsAndConditionsShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            },
            showPrivacyPolicy: function () {
                this.modals.privacyPolicyShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            },
            setFocus: function (e) {
                e.target.focus();
            },
            async handleSubmit() {

                let appForm = this.$refs.appform;
                let addressesValid = true;
                this.formErrors = [];
                let formErrors = this.formErrors;
                appForm.errors = [];
                const validationResult = await appForm.validate();
                await new Promise(r => setTimeout(r, 20))

                let lastAddress = this.addresses[this.addresses.length - 1];
                if (lastAddress.showAddAddress == true || lastAddress.showAddressWarning == true) {
                    lastAddress.showAddAddress = false;
                    lastAddress.showAddressWarning = true;
                    addressesValid = false;

                    formErrors.push({
                        errorMessage: ['Please add another address to continue.'],
                        fieldName: 'address[' + lastAddress.id + '].addAddressButton'
                    })
                }

                if (addressesValid) {
                    if (!lastAddress.addressSearched && !lastAddress.showManualEntry) {
                        lastAddress.addressSearchedWarning = true;

                        formErrors.push({
                            errorMessage: ['Please find your address.'],
                            fieldName: 'address[' + lastAddress.id + '].findAddressButton'
                        })


                        addressesValid = false;
                    }
                    else if (!lastAddress.addressConfirmed && !lastAddress.showManualEntry) {
                        lastAddress.addressConfirmedWarning = true;
                        addressesValid = false;


                        formErrors.push({
                            errorMessage: ['Please confirm that this address is correct.'],
                            fieldName: 'address[' + lastAddress.id + '].verifyAddress'
                        })
                        if (this.affordability != null) {
                            // Clearing hidden fields
                            if (this.affordability.incomeToDecrease == "false") {
                                this.affordability.newGrossAnnualIncome = null;
                            }
                            if (this.affordability.spendToIncrease == "false") {
                                this.affordability.monthlySpendIncrease = null;
                            }
                        }
                    }
                }

                if (validationResult.valid && addressesValid) {
                    this.showLoading = true;

                    await this.CLEAR_VALIDATION_ERRORS;

                    this.application.grossAnnualIncome = parseCurrency(this.application.grossAnnualIncome)
                    this.affordability.newGrossAnnualIncome = parseCurrency(this.affordability.newGrossAnnualIncome)
                    this.affordability.monthlySpendIncrease = parseCurrency(this.affordability.monthlySpendIncrease)
                    this.affordability.childcareCost = parseCurrency(this.affordability.childcareCost)
                    this.affordability.dependants = parseCurrency(this.affordability.dependants)

                    await this.submitApplication({ application: this.application, affordability: this.affordability, retailerCode: this.retailer.retailerCode });

                    //handle stuff from response here
                    var errorList = this.validationErrors;

                    if (Object.keys(errorList).length > 0) {

                        for (var i = 0; i < Object.keys(errorList).length; i++) { // If multiple errors, only display first to avoid duplicate key

                            formErrors.push({
                                errorMessage: errorList[i].value,
                                fieldName: errorList[i].key
                            });
                        }

                        this.displayErrors();
                        this.showLoading = false;
                    }
                    else {
                        //set the last address to summary mode for if it's reloaded. Probably wants moving from here though
                        lastAddress.showPostcodeLookup = false;
                        lastAddress.showPicklist = false;
                        lastAddress.showManualEntry = false;
                        lastAddress.showDateMovedToAddress = false;
                        lastAddress.showAddAddress = false;
                        lastAddress.showAddressWarning = false;
                        lastAddress.showSummary = true;
                        lastAddress.showSummaryTop = true;
                        lastAddress.showSummaryBottom = true;

                        this.finishedLoading = true;
                    }


                }
                else {
                    this.displayErrors();
                }
            },
            displayErrors() {
                this.showErrorSummary = true;
                scroll(0, 0);
                //seems to error without a slight wait. Maybe need to wait for next tick instead.
                this.$nextTick(() => {
                    document.getElementById('error-summary').focus();
                });
            },
            monthDiff(d1, d2) {
                var months;
                months = (d2.year() - d1.year()) * 12;
                months -= d1.month() + 1;
                months += d2.month();
                return months <= 0 ? 0 : months;
            },
            updateAddressTemplate: function () {
                this.ADD_ADDRESS();
            },
            deleteAddress(address) {
                this.REMOVE_ADDRESS({
                    id: address.id
                })

                if (this.application.addresses.length == 0) {
                    this.ADD_ADDRESS();

                    this.$nextTick(() => {

                        document.getElementById("address[" + lastAddress.id + "].postcodeLookup").focus();
                    });
                }
                else {
                    this.$nextTick(() => {
                        document.getElementById("address[" + lastAddress.id + "].addAddressButton").focus();
                    });
                }

                let lastAddress = this.addresses[this.addresses.length - 1];

                //for accessibility, focus the top new element



                lastAddress.nextAddressAdded = false;
                if (lastAddress.dateMovedToAddress) {

                    //don't want this repeat logic left here
                    const momentDate = moment(lastAddress.dateMovedToAddress, 'DD-MM-YYYY');
                    const currentMomentDate = moment();

                    if (this.monthDiff(momentDate, currentMomentDate) < 36 && !lastAddress.showAddressWarning) {
                        lastAddress.showAddAddress = true;
                    }
                    else {
                        lastAddress.showAddAddress = false;
                        lastAddress.showAddressWarning = false;
                    }
                }
            },
            deleteAddressesAfterIndex(addressIndex) {
                this.REMOVE_ADDRESSES_AFTER_INDEX({
                    id: addressIndex + 1
                })
            },
            endLoading: function () {
                this.$router.push('/results');
            },
            scrollInput(inputID) {
                var inputElement = document.getElementById(inputID);

                // Fix for radio buttons. Focus on element if tabable, oterwise focus on child
                if (inputElement.tagName.toLowerCase() == "fieldset") {
                    inputElement = inputElement.getElementsByTagName('input')[0];
                }

                inputElement.focus();
                inputElement.scrollIntoViewCenter();
            }
        },
        created() {
            this.modals.termsAndConditionsShown = false;
            this.modals.privacyPolicyShown = false;
            this.application.dateOfBirth = this.dateOfBirth;
            this.application.grossAnnualIncome = this.grossAnnualIncome,
                this.INITIALISE_AFFORDABILITY();
        },
        mounted() {
            //this ties up the inputs to the last element functionality required for restoring focus after modal.
            let inputs = document.querySelectorAll('input, .button, [role="button"]');
            const instance = this;

            for (var i = 0, len = inputs.length; i < len; i++) {
                inputs[i].addEventListener('focus', function () {
                    let element = this;

                    instance.SET_LAST_ELEMENT({
                        lastElement: element.id
                    });
                })
            }
        }
    }

</script>

<style lang="scss" scoped>

    .support-text {
        margin-top: 5px;
        font-weight: 500;
        font-size: 14px;
        clear: both;
        display: block;
    }

    .error-summary {
        padding: 5%;
        border: 1px solid $error;
        border-radius: 5px;
        background-color: $background-decline-error;
        margin-bottom: 20px;
    }

    .error-summary-header-image {
        display: inline-block;
        position: relative;
        top: -30px;
    }


    @media only screen and (min-width: 1000px) {
        .error-summary-header-image {
            top: 0;
        }
    }

    .error-summary-header p {
        font-size: 1.625rem;
        line-height: 32px;
        color: $titles;
    }

    .summary-link {
        color: $interactive;
        text-decoration: underline;
        cursor: pointer;
    }

    #tooltip {
        display: none;
    }
</style>
