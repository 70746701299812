<template>
    <div class="page">
        <h2 tabindex="-1" class="main-anchor">You might be eligible but we need more information</h2>
        <p>Based on the information provided you might be eligible for finance. However, we will need to check more details and carry out a full credit search at application stage.</p>
        <p>If you do choose to apply, there is no guarantee you'll be approved.</p>
        <p>This check has not affected your credit score.</p>

        <div class="what-happens-next">
            <h2>What happens next?</h2>

            <ul>
                <li>
                    You can still apply for finance by choosing to pay with Barclays at the checkout.
                </li>
                <li>
                    We'll need to take more details and carry out a full search. However, there is no guarantee you'll be approved.
                </li>
                <li>
                    You can also complete your purchase using another payment method at checkout.
                </li>
                <li>
                    Credit is subject to application, financial circumstances and borrowing history.
                </li>
            </ul>
        </div>

        <click-out-link v-if="retailer.clickoutLink"></click-out-link>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import ClickOut from './ClickOut.vue'

    export default {
        computed: {
            ...mapGetters(['retailer'])
        },
        components: {
            'click-out-link': ClickOut,
        }
    }

</script>

<style lang="scss" scoped>

    .what-happens-next {
        background-color: $background2;
        border-radius: 5px;
        padding: 10px 30px;
        margin-bottom: 30px;
    }
</style>