<template>
    <div :class="{'form-row':true, 'is-valid': !errorMessage && meta.dirty}">
        <div @keyup.capture.prevent="updateValue">

            <div class="form-row-label">
                <label for="time-at-address" v-if="addressIndex == 0" id="dateMovedToAddressLabel">Date you moved to your {{ addressIndex == 0 ? 'current' : 'previous' }} address <i>(Expected format of MM, YYYY. Required)</i></label>
            </div>

            <input v-model="value" :id="'address[' + addressIndex + '].timeAtAddress'" aria-hidden="true" hidden />

            <div class="form-row-control time-at-address-months-control">
                <input v-model="months"
                       :id="'address[' + addressIndex + '].timeAtAddressMonths'"
                       :name="'address[' + addressIndex + '].timeAtAddressMonths'"
                       aria-label="Date moved to address months in format MM"
                       :aria-describedby="'address[' + addressIndex + '].dateMovedToAddressErrors'"
                       type="number"
                       inputmode="numeric"
                       :tabindex="modalShown ? -1 : 0"
                       :ref="'address[' + addressIndex + '].dateMovedToAddressMonths'"
                       @input="value => months = updateInputValue(value.currentTarget.value, 2)"
                       @blur="() => months = pad(months, 2)"
                       :class="{'invalid': errorMessage, 'valid': !errorMessage && meta.touched}" 
                       :aria-invalid="!!errorMessage" />
                
            </div>

            <div class="month-year-divider">
                /
            </div>

            <div class="form-row-control time-at-address-years-control">
                <input v-model="years"
                       :id="'address[' + addressIndex + '].timeAtAddressYears'"
                       :name="'address[' + addressIndex + '].timeAtAddressYears'"
                       aria-label="Date moved to address years in format YYYY"
                       :aria-describedby="'address[' + addressIndex + '].dateMovedToAddressErrors'"
                       type="number"
                       inputmode="numeric"
                       :tabindex="modalShown ? -1 : 0"
                       @input="value => years = updateInputValue(value.currentTarget.value, 4)"
                       @blur="validateYearMonth()"
                       :class="{'invalid': errorMessage, 'valid': !errorMessage && meta.touched}"
                       :aria-invalid="!!errorMessage" />
            </div>
        </div>

        <div class="approximate-date-label">
            <p>You can provide an approximate date.</p>
        </div>

        <span :class="{'invalid-text': errorMessage, 'form-row-validation':true }" v-if="errorMessage" :id="'address[' + addressIndex + '].dateMovedToAddressErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ errorMessage }}</span>
        </span>
    </div>
</template>

<script setup>
    import moment from 'moment'
    import { defineProps, ref, computed, defineEmits, toRef } from 'vue'
    import { useStore } from 'vuex'
    import { useField, defineRule } from 'vee-validate'

    const props = defineProps({
        modelValue: {
            default: '',
            required: true
        },
        addressIndex: {
            type: Number,
            default: 0,
            required: true
        }
    })

    const emit = defineEmits(['updateDateMovedToAddress', 'input'])
    const months = ref(pad((moment(props.modelValue, 'DD-MM-YYYY').month() + 1).toString(), 2))
    const years = ref((moment(props.modelValue, 'DD-MM-YYYY').year()).toString())
    const store = useStore()
    const addressIndex = toRef(props.addressIndex)

    /* eslint-disable no-unused-vars */
    const modalShown = computed(() => store.getters.modalShown)
    const markedCompleted = computed(() => months.value.length == 2 && years.value.length == 4)

    // Validation

    defineRule('valid_month', value => {
        if (!value) return true;

        let months = value.split('-')[1];

        if (!months) return true;

        if (months <= 0 || months > 12) return false;

        return true;

    })

    defineRule('valid_year', value => {
        if (!value) return true;

        let years = value.split('-')[2];

        if (!years) return true;

        if ((years && years.length < 4) || parseInt(years) < 1000) {
            return false;
        }

        return true;
    })

    defineRule('less_than_years', value => {
        if (!value) return true;

        let formattedDate = moment(value, 'DD-MM-YYYY');
        let today = moment();
        if (today.year() - formattedDate.year() > 111) {
            return false;
        }

        return true;
    })

    defineRule('not_in_future', value => {
        if (value === '' || value === undefined || value === null) return true;

        let today = moment();
        let dateValue = moment(value, 'DD-MM-YYYY');

        if (today < dateValue) return false;
        return true;
    })

    const { value, errorMessage, meta, validate } = useField(`address[${addressIndex.value}].timeAtAddressMonths`, 'required_date|valid_month|valid_year|date|less_than_years|not_in_future', { keepValueOnUnmount: true })

    async function validateYearMonth() {
        await validate();
    }

    function updateValue() {

        if (markedCompleted.value) {
            let dateString = "01-" + months.value + "-" + years.value

            value.value = dateString

            emit('updateDateMovedToAddress', dateString)
            emit('input', dateString)
        }
        else {
            emit('updateDateMovedToAddress', null)
            emit('input', null)
            value.value = null
        }
    }

    function updateInputValue(value, max) {
        let newValue = value.toString().substring(0, max);
        return newValue;
    }

    function pad(stringToPad, maxLength) {
        if (stringToPad == '') {
            return stringToPad
        }
        else if (stringToPad.length < maxLength) {
            return padZero(stringToPad, maxLength)
        }

        return stringToPad
    }

    function padZero(value, maxLength) {
        const padChar = '0'
        while (value.length < maxLength) value = padChar + value
        return value
    }
    /* eslint-disable no-unused-vars */

    // Load saved value into validator
    if (markedCompleted.value) {
        updateValue()
    }
</script>

<style lang="scss" scoped>

    @import '../../node_modules/hd-styles/modules/mixins';

    .input-day {
        @include grid-size(calc(6/24));
        margin-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(2/24));
            margin-left: 0;
        }
    }

    .input-month {
        @include grid-size(calc(6/24));
        @include grid-push(calc(2/24));
        margin-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(2/24));
            margin-left: 0;
        }
    }

    .input-year {
        @include grid-size(calc(6/24));
        @include grid-push(calc(2/24));
        margin-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(2/24));
            margin-left: 0;
        }
    }

    .date-divider {
        float: left;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 35px;
    }

    .time-at-address-months-control {
        @include grid-size(calc(6/24));
        padding-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(3/24));
            padding-left: 0;
            margin-top: 0;
        }
    }

    .time-at-address-years-control {
        @include grid-size(calc(8/24));
        padding-left: 10px;

        @include bp(tablet) {
            @include grid-size(calc(4/24));
            padding-left: 0;
        }

        & input {
            padding-left: 10px;
        }
    }

    .month-year-divider {
        @include grid-size(calc(1/24));
        height: 40px;
        padding-top: 10px;
        padding-left: 0;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
    }

    .approximate-date-label {
        @include grid-size(calc(24/24));
        padding-left: 0;
        margin-top: 4px;
    }
</style>