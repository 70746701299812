<template>
    <div @focusout="closeTool()"
         @mouseover="openTool()"
         @mouseleave="closeTool()" class="tooltip-container">
        <img src="../assets/icon-information.svg" class="tooltip-icon"/>
        <div v-if="enabled" class="tooltip">
            <slot />
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'

    /* eslint-disable no-unused-vars */
    const enabled = ref(false)


    function setTouchScreen() {
        this.isTouchScreen = true
    }

    function openTool() {
        this.enabled = true
    }

    function closeTool() {
        this.enabled = false
    }

    function toggleTool() {
        if (document.getElementById(this.tooltipTextId) == null) {
            this.openTool()
        }
        else {
            this.closeTool()
        }

        document.getElementById(this.tooltipId).focus()
    }
    /* eslint-disable no-unused-vars */
</script>

<style lang="scss" scoped>
    .tooltip-container {
        display: inline-block;
        position: relative;
        cursor: pointer;
    }

    .tooltip {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateX(-50%) translateY(10px);
        background-color: #0076b6;
        color: #fff;
        text-align: center;
        padding: 5px 10px 4px;
        border-radius: 5px;
        white-space: normal;        
        width: 250px;
        opacity: 1;
        pointer-events: none;
        z-index: 10;
    }

    .tooltip-icon-container {
        top: 5px;
        left: 5px;
        display: inline-block;
        position: relative;
    }

    .tooltip-icon {
        height: 24px;
        width: 24px;
    }

    .tooltip-text-container {
        @media (max-width:600px) {
            position: absolute;
            left: 0;
        }
    }
</style>

