<template>
    <div>
        <div :class="{'form-row':true, 'is-valid': !hasErrors && isValid}">
            <div class="form-row-label">
                <label for="dependants" id="dependantsLabel" v-html="retailer.content.NumDependantsQuestion"></label>

                <tooltip tooltipId="dependantsTooltip"
                         tooltipTextId="dependantsTooltipText"
                         labeledBy="dependantsLabel tooltip"
                         v-if="retailer.content.ShowNumDependantsTooltip==true">
                    <p>A financial dependent is anyone who depends on you for day to day financial support I.e money, clothes or food. This might include children, relatives or spouses. Do not include children supported by maintenance payments you make. </p>
                </tooltip>
            </div>

            <div class="form-row-control">
                <select id="dependants"
                        v-model="dependants"
                        name="dependants"
                        aria-describedby="dependantsErrors"
                        :tabindex="modalShown ? -1 : 0"
                        :class="{'invalid': hasErrors, 'valid': !hasErrors && isValid}" >
                    <option value="" selected disabled>Please select one</option>
                    <option value=0>0</option>
                    <option value=1>1</option>
                    <option value=2>2</option>
                    <option value=3>3</option>
                    <option value=4>4</option>
                    <option value=5>5</option>
                    <option value=6>6+</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mapFields } from "vuex-map-fields";
    import Tooltip from "@/components/Tooltip";

    export default {
        name: 'dependants-num-question',
        props: {
            hasErrors: {
                required: true
            },
            isValid: {
                type: Boolean,
                required: true
            }
        },
        components: {
            'tooltip': Tooltip
        },
        computed: {
            ...mapGetters(['affordability', 'modalShown', 'application', 'retailer']),
            ...mapFields([
                'affordability.dependantsCheck',
                'affordability.dependants'
            ])
        }
    }
</script>