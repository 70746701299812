<template>
    <div class="header-container">
        <div class="header">
            <div class="header-top">
                <div class="header-top-inner">
                    <div class="logo-container">
                        <img class="header-logo-image" src="../assets/barclays-logo-eagle.svg" alt="Barclays logo" />
                        <img class="header-logo-text" src="../assets/barclays-logo-text.svg" alt="" />
                    </div>
                </div>
            </div>
            <div class="header-bottom">
                <div class="header-bottom-inner">
                    <h1>Eligibility check</h1>
                </div>
            </div>
        </div>

        <cookie-banner v-if="!modals.cookiesAccepted"/>

    </div>
</template>


<script>

    import { mapGetters } from 'vuex'
    import CookieBanner from './CookieBanner.vue';

    export default {
        computed: {
            ...mapGetters(['modals', 'modalShown']),
        },
         components: {
            'cookie-banner': CookieBanner,
        },
    }

</script>

<style lang="scss" scoped>

    .header-top-inner, .header-bottom-inner
    {
        width:90%;
        margin: 0 auto;

        @include bp(tablet)
        {
            width:70%;
        }
    }

    .header-top-inner
    {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .header-bottom
    {
        background: $background-tooltip-hero;
        border-top: 3px solid $dividers;
        padding-top: 30px;
        padding-bottom: 30px;

        & .header-bottom-inner
        {
            border-left: 3px solid $branding;

            & h1
    {
        padding-left: 20px;
        margin-bottom: 0;
        margin-top: 0;
    }

    }
    }

    .header-logo-text {
        position: relative;
        top: -3px;
        left: 5px;
    }

    .header-container
    {
        margin-bottom: 20px;
    }

</style>