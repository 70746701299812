<template>
    <div class="question-container" @click.prevent.stop="toggleExtraInfo()">
        <button class="question-container-button"
                ref="questioncontainerbutton"
                tabindex="0"
                aria-expanded="false"
                @click.prevent.stop="toggleExtraInfo()"
                @keydown.enter.prevent="toggleExtraInfo()"
                @keydown.space.prevent="toggleExtraInfo()">
            <div class="question-image">
                <img src="../assets/chevron.svg" alt="" />
            </div>
            <div class="question">
                <p>{{headlineText}}</p>
            </div>
        </button>
            <transition name="fade">
                <div class="extra-information" v-if="showExtraInfo == true" v-html="extraInfoText"/>
            </transition>
        
    </div>
</template>

<script>

    export default {
        props: {
            headlineText: {
                type: String,
                required: true
            },
            extraInfoText: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showExtraInfo: false
            };
        },
        methods: {
            toggleExtraInfo: function () {
                this.showExtraInfo = !this.showExtraInfo;
                if (this.showExtraInfo) {
                    this.$refs.questioncontainerbutton.setAttribute('aria-expanded', 'true');
                }
                else {
                     this.$refs.questioncontainerbutton.setAttribute('aria-expanded', 'false');
                }
                this.$refs.questioncontainerbutton.focus();
            }
        }
    }

</script>

<style lang="scss" scoped>

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    button.question-container-button {
        width:100%;
        cursor: pointer;
        color: inherit;
        text-align:left;
        border-top-color:transparent;
        font: inherit;
        font-size:inherit;
        background:none;
        border:none;
    &:focus
    {
        outline: solid $branding 1.5px;
    }
}
    
    .question {
        display: inline-block;
        cursor: pointer;
        & p
        {
            display: inline-block;
            padding-left: 24px;
        }
    }

    .question-image {
        width: 20px;
        height: 20px;
        position: relative;
        top: 18px;
    }

    .extra-information
    {
        padding-left: 24px;
    }
</style>