<template>
    <div class="modal-background">
        <div class="modal-container loading">
            <div class="modal-container-inner">
                <h2 aria-live="assertive" aria-atomic="true" tabindex="-1" ref="loadingHeader" aria-describedby="loadingSupportText">Please wait</h2>

                <p id="loadingSupportText">We're just doing some background checks - it shouldn't take long</p>

                <svg viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg">
                    <circle class="base" cx="95" cy="95" r="90" stroke-width="10" fill="none" />
                    <circle class="path" cx="95" cy="95" r="90" stroke-width="10" fill="none" stroke="#707070" stroke-linecap="round" ref="loadingPath" />
                </svg>

                <div class="inner-progress-container">
                    <p class="inner-progress-text-percent" ref="loadingPercentage"></p>
                    <p class="inner-progress-text">Complete</p>
                </div>
                <p class="additional-progress-text" ref="additionalProgressText">Almost there</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            finishedLoading: {
                type: Boolean
            }
        },
        data: function () {
            return {
                loadingPercent: 0,
            }
        },
        computed: {
            radius() {
                return this.$refs.loadingPath.r.baseVal.value;
            },
            circumference() {
                return this.radius * 2 * Math.PI
            }
        },
        timers: {
            updateLoading: {
                time: 1000,
                autostart: true,
                repeat: true
            }
        },
        methods: {
            updateLoading() {

                if (this.loadingPercent < 90) {
                    this.loadingPercent += 10;
                }
                else if (this.loadingPercent < 100) {
                    this.loadingPercent = 95;
                    this.$refs.additionalProgressText.style.display = "block";
                }

                if (this.$refs.loadingPath) {
                    this.$refs.loadingPath.style.strokeDashoffset = this.circumference * (1 - (this.loadingPercent / 100)) + 'px'; // Need to add the px for safari
                    this.$refs.loadingPercentage.textContent = this.loadingPercent + "%";
                }
            }
        },
        mounted() {
            this.$refs.loadingPath.style.strokeDasharray = this.circumference;
            this.$refs.loadingPath.style.strokeDashoffset = this.circumference - 1 + 'px';
            this.$refs.loadingPercentage.textContent = "0%";
            this.$refs.loadingPath.style.display = "block";
            this.$refs.loadingHeader.focus();
        },
        watch: {
            async finishedLoading(newState) {
                if (newState == true) {
                    this.loadingPercent = 100
                    await new Promise(r => setTimeout(r, 2000))
                    this.$emit('endLoading');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .loading {
        text-align: center;
    }

    svg {
        transform: rotate(270deg);
        width: 200px;
        height: 200px;
    }

    .base {
        stroke: $interactive;
        stroke-opacity: 0.5;
    }

    .path {
        transform-origin: 50% 50%;
        stroke: $interactive;
        display: none;
        transition: 0.35s stroke-dashoffset;
    }

    .inner-progress-container {
        position: relative;
        top: -130px;
    }

    .inner-progress-text-percent {
        font-size: 24px;
        padding-left: 5px;
        margin-bottom: 0;
    }

    .additional-progress-text {
        display: none;
        position: relative;
        top: -50px;
        height: 0;
        margin: 0;
    }
</style>
