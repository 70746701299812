<template>
    <div :class="{'form-row':true, 'is-valid': !errorMessage && meta.dirty}">

        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].picklist'" :id="'address[' + addressIndex + '].picklistLabel'">Select your address <i>(Required)</i></label>
        </div>

        <div class="form-row-control">
            <select :id="'address[' + addressIndex + '].picklist'"
                    :name="'address[' + addressIndex + '].picklist'"
                    @blur="handleBlur"
                    @change="updateValue"
                    :class="{'invalid': errorMessage,  'valid': !errorMessage && meta.touched}"
                    v-model="value"
                    :ref="'address[' + addressIndex + '].picklist'"
                    :aria-describedby="'address[' + addressIndex + '].picklistErrors'"
                    :tabindex="modalShown ? -1 : 0"
                    :aria-invalid="errorMessage ? true : false">
                <option value selected disabled>Please select one</option>
                <option v-for="(item, key) in address.picklist"
                        :value="item.Key"
                        :key="key">
                    {{item.Value}}
                </option>
            </select>
        </div>

        <div class="button-container-inner search-postcode">
            <button @click.prevent="updateAddress"
                    @keydown.enter="updateAddress"
                    class="button secondary-button-text float-left find-address"
                    :tabindex="modalShown ? -1 : 0"
                    type="button"
                    :id="'address[' + addressIndex + '].verifyAddress'"
                    :ref="'address[' + addressIndex + '].verifyAddress'">
                Select address
            </button>
        </div>

        <span v-if="address.addressConfirmedWarning && !errorMessage" :class="{'invalid-text': errorMessage, 'form-row-validation':true, 'additional-error':true }" >
            <span class="error-bold">Error: </span><span> Please confirm the above address.</span>
        </span>

        <span :class="{'invalid-text': errorMessage, 'form-row-validation':true }" v-if="errorMessage" :id="'address[' + addressIndex + '].picklistErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ errorMessage }}</span>
        </span>

        <span :id="'address[' + addressIndex + '].manualEntryButton'" class="manual-entry-label" @click.prevent="manualEntry" @keydown.enter.prevent="manualEntry" role="button" :tabindex="modalShown ? -1 : 0">Enter your address manually</span>

    </div>
    <!--</validation-provider>-->
</template>

<script setup>
    import { defineProps, ref, toRef, defineEmits, nextTick, computed } from 'vue'
    import { useStore } from 'vuex'
    import { useField, configure } from 'vee-validate'
    import { localize } from '@vee-validate/i18n'
    /* eslint-disable no-unused-vars */

    const props = defineProps({
        address: {
            type: Object,
            required: true
        },
        addressIndex: {
            type: Number,
            required: true
        }
    })

    const emit = defineEmits(['deleteAddress', 'updateAddress'])
    const searchingAddress = ref(false)
    const showPostcodeError = ref(false)
    const address = toRef(props.address)
    const addressIndex = toRef(props.addressIndex)
    const store = useStore()
    const resolveAddress = address => store.dispatch('resolveAddress', address) 

    /* eslint-disable no-unused-vars */
    const modalShown = computed(() => store.getters.modalShown)

    const { value, errorMessage, meta, handleBlur } = useField(`address[${addressIndex.value}].picklist`, 'required')

    configure({
        generateMessage: localize('en', {
            fields: {
                [`address[${addressIndex.value}].picklist`]: {
                    required: `Please select an address.`
                }
            },
        }),
    })

    function manualEntry() {
        address.value.showPostcodeLookup = false
        address.value.showPicklist = false
        address.value.showManualEntry == true ? address.value.showManualEntry = false : address.value.showManualEntry = true;
        address.value.showDateMovedToAddress = true
        address.value.showSummary = false

        //for accessibility, focus the top new element
        nextTick(() => {
            document.getElementById(`address[${addressIndex.value}].houseNumber`).focus()
        })
    }

    function updateAddress() {
        emit('updateAddress')
    }

    function updateValue() {
        address.value.selectedAddress = value
    }

    /* eslint-disable no-unused-vars */
</script>

<style lang="scss" scoped>
    @import '../../node_modules/hd-styles/modules/mixins';

    .address-header h5, .address-header-left h5 {
        margin-top: 10px;
    }

    .address-header-left {
        width: 50%;
        display: inline-block;
    }

    .address-header-right {
        float: right;
        display: inline-block;
        margin-top: 10px;
    }

    .form-row-postcode {
        @include grid-size(calc(24/24));
        text-transform: uppercase;
        padding-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(5/24));
            padding-left: 0;
        }
    }

    .find-address {
        margin-top: 30px;

        @include bp(tablet) {
            margin-top: -9px;
            margin-left: 10px;
        }
    }

    .manual-entry-label, .use-postcode-lookup {
        /*@include grid-size(calc(24/24));*/
        display: inline-block;
        float: none;
        padding-left: 0;
        padding-top: 20px;
        cursor: pointer;
        text-decoration: underline;
        color: $interactive;
        clear: both;
        width: auto;
        white-space: nowrap;
    }

    .add-address-container {
        background-color: $background-tooltip-hero;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $interactive;
    }

    .address-warning-container, .qas-error-container {
        background-color: $background-warning;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $error;
        display: block;
    }

    .qas-error-container {
        margin-bottom: 40px;
    }

    .summary-header, .summary-main, .summary-main-top-inner, .summary-main-bottom-inner {
        @include grid-context();
        margin-left: 0;
        padding-left: 0;
    }

    .summary-header-left {
        @include grid-size(calc(18/24));
        padding-left: 0;
        font-weight: bold;
    }

    .summary-header-right {
        @include grid-size(calc(6/24));
        text-align-last: right;
    }

    .summary-main-top, .summary-main-bottom {
        @include grid-size(calc(24/24));
        padding-left: 0;
    }

    .summary-main-bottom-inner, .summary-main-top-inner {
        border-bottom: 1px solid $dividers;
        padding-top: 20px;
        padding-bottom: 20px;

        @include bp(tablet) {
            border-left: 1px solid $dividers;
            border-right: 1px solid $dividers;
            border-radius: 5px;
        }
    }

    .summary-main-top-inner {
        border-top: 1px solid $dividers;
        border-bottom: 1px solid $dividers;
        padding-bottom: 20px;
    }

    .summary-left {
        font-weight: bold;
        @include grid-size(calc(12/24));
        padding-left: 20px;
    }

    .summary-right {
        @include grid-size(calc(12/24));
    }

    .summary {
        margin-bottom: 60px;
    }

    .edit-address, .edit-address-desktop, .delete-address, .delete-address-desktop {
        text-decoration: underline;
        color: $interactive;
        cursor: pointer;
    }

    .edit-address, .delete-address {
        @include bp(tablet) {
            display: none;
        }
    }

    .edit-address-desktop, .delete-address-desktop {
        display: none;

        @include bp(tablet) {
            display: inline-block;
        }
    }

    .summary-right-flatNumber {
        margin-right: 4px;
    }

    .summary-right-flatNumber, .summary-right-houseName {
        display: inline-block;
        position: relative;
    }

    .address-warning-left {
        display: inline-block;
        vertical-align: top;
    }

    .address-warning-right {
        display: inline-block;
        width: 80%;
        padding-left: 30px;
    }

    .disabledButton {
        cursor: not-allowed;
        background-color: rgb(229, 229, 229) !important;
    }

    .disabledButton > * {
        pointer-events: none;
    }

    .additional-error {
        color: $error;
    }
</style>

