<template>
    <input :class="{'invalid': hasErrors, 'valid': !hasErrors && isValid}" :value="displayValue" @input="value => displayValue = value.currentTarget.value" inputmode="numeric" />
</template>

<script>
    export default {
        name: 'CurrencyInput',
        props: {
            value: {},
            maxLength: {
                required: true
            },
            hasErrors: {
                required: true
            },
            isValid: {
                type: Boolean,
                required: true
            }
        }, 
        computed: {
            displayValue: {
                get: function () {
                    if (this.value === undefined || this.value === null) {
                        return this.value;
                    }
                    else {
                        if (this.value[0] == 0) {
                            return 0;
                        }
                        let newValue = this.ThousandSeparate(this.value.toString());
                        return newValue;
                    }
                },
                set: function (modifiedValue) {
                    var intString = modifiedValue.toString().replace(/[^0-9]/g, '').substring(0, this.maxLength);

                    if (intString == '') {
                        this.$emit('input', null)
                    }
                    else {
                        this.$emit('input', parseInt(intString))
                    }
                    this.$forceUpdate();
                }
            }
        },
        methods: {
            ThousandSeparate: function (value) {
                value = value.replace(/[^0-9]/g,'');
                var pattern = new RegExp('(-?[0-9]+)([0-9]{3})');
                while (pattern.test(value)) {
                    value = value.replace(pattern, '$1,$2');
                }
                return value;
            }
        }
    }
</script>
