import axios from 'axios'
import router from '../router'

export const generateXSRFToken = () => {
    return axios
        .get('generateXSRFToken')
        .catch(err => {
            console.log(err)
        })
}

export const submitApplication = async ({ commit, getters }, payload) => {
    //move this but okay for now
    if (payload.application.middleName == "") payload.application.middleName = null;


    await axios
        .post('submitApplication', { application: payload.application, affordability: payload.affordability, retailerCode: payload.retailerCode, sessionID: getters.sessionID})
        .then(response => {
            if (response.status === 200) {
                commit('SET_RESULT', {
                    resultType: response.data.resultType,
                    faqItems: response.data.faqItems,
                    repaymentResults: response.data.repaymentResults
                });

                return response;
            }
        })
        .catch(err => {
            
            var validationErrors = err.response.data.validationErrors;
            if (validationErrors != null ) {
                commit('SET_VALIDATION_ERRORS', {
                    validationErrors: validationErrors
                });
                return err;
            }
            
            router.push('/error');
        })
}

export const getConfig = ({ commit }, payload) => {
    return axios
        .get(`getConfig`,
            {
                params: {
                    retailerCode: payload.retailerCode
                }
            })
        .then(response => {

            if (response.data.retailerConfiguration.errorCode) {
                router.push('/error');
            }

            commit('ALL_CONFIG', {
                responseData: response.data,
                retailerCode: payload.retailerCode
            })
        })
        .catch(err => {
            console.log(err)
        })
}

export const saveSessionEvent = ({ commit, getters }, payload) => {
    return axios
        .get(`saveSessionEvent`
            , { params: {
                    sessionID: getters.sessionID,
                    retailerCode: payload.retailerCode,
                    eventType: payload.eventType
                    }
                }
        )
        .then(response => {

            commit('SESSION_ID', {
                sessionID: response.data
            })
        })
        .catch(err => {
            console.log(err)
        })
}

export const resolveAddress = ({ commit }, payload) => {
    //TODO: Handle error cases
    return axios
        .post("resolveAddress",
            {
                postCode: payload.postcode,
                houseNameNumber: payload.houseNameNumber,
                addressIndex: payload.addressIndex
            }
            )
        .then(response => {
            if (response.status === 200) {
                let address = response.data.HDAddress;

                switch (response.data.ResultType) {
                    case 0:
                        commit('FILL_ADDRESS', {
                            buildingNumber: address.buildingNumber,
                            buildingName: address.buildingName,
                            street: address.street,
                            townCity: address.town,
                            postcode: address.postCode,
                            addressIndex: payload.addressIndex,
                            resultType: response.data.ResultType
                        });
                        break;
                    case 1:
                        commit('UPDATE_PICKLIST', {
                            picklist: response.data.Picklist,
                            addressIndex: payload.addressIndex,
                            resultType: response.data.ResultType
                        });
                        break;
                    default:
                        console.warn(`Unknown resultType: ${response.data.ResultType}`);
                        break;
                }
            }
        })
        .catch(err => {
            console.log(err);
        });
}

export const fillAddress = ({ commit }, payload) => {
    //TODO: Handle error cases
    return axios
        .post('fillAddress', {
                addressMoniker: payload.addressMoniker,
                addressIndex: payload.addressIndex
            })
        .then(response => {
            if (response.status === 200) {
                let address = response.data.HDAddress
                if (address == null) {
                    commit('UPDATE_ADDRESS_RESULT', {
                        addressIndex: payload.addressIndex,
                        resultType: response.data.ResultType
                    });
                }
                else {
                    commit('FILL_ADDRESS', {
                        buildingNumber: address.buildingNumber,
                        buildingName: address.buildingName,
                        flatNumber: address.flat,
                        street: address.street,
                        townCity: address.town,
                        postcode: address.postCode,
                        addressIndex: payload.addressIndex,
                        resultType: response.data.ResultType
                    });
                }
            }
        })
        .catch(err => {
            console.log(err)
        })
}

export const closeModal = ({ commit, state }) => {
    commit('CLOSE_MODAL');

    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-noscroll');

    let lastElement = state.modals.lastElement;
    if (lastElement) {
        try {
            document.getElementById(lastElement).focus();
        }
        catch {
            //log error?
        }
    }
}
