<template>
    <div class="modal-background" @click.self="close">
        <div class="modal-container">

            <span class="close-modal" ref="closeModalButton" @click.self.prevent="close" @keydown.enter.prevent="close" tabindex="0" aria-label="close dialog" role="button">x</span>

            <div class="modal-container-inner modal-scrolling">
                <div class="modal-header">
                    <h2>Privacy Policy</h2>
                </div>

                <div v-if="retailer.displayAffordabilityResults == false">
                    <p>
                        By completing and submitting this form, your personal data will be used to make an assessment to indicate whether you are likely to be approved for finance with Barclays Partner Finance. Your details will be shared with the relevant credit reference agencies and companies acting on our behalf to carry out the quotation search and will be used solely for this purpose. Your personal data will be kept confidential and will not be stored for longer than necessary. We will store the information you supply to us for the quotation search on our systems. This service won't affect your ability to obtain credit in the future and will not be seen by any other lenders. Any personal information you give us is protected over the Internet using secure web server technology.
                    </p>
                    <p>
                        The pre-application check will provide you with an indication of whether you are likely to be approved for finance with Barclays Partner Finance based on the information you have provided and information obtained from credit reference agencies. The result that will be shown has been assessed for you based on an initial check of your credit record and the additional information you have provided. This does not constitute an offer of finance for your purchase and you may be declined once a full assessment of your application has been completed. This scoring method only applies to Barclays Partner Finance, as other companies may use different scoring methods.
                    </p>
                </div>

                <div v-if="retailer.displayAffordabilityResults == true">
                    <p>
                        By completing and submitting this form, your personal data will be used to make a suitable loan suggestion for you. Your details will be shared with the relevant credit reference agencies and companies acting on our behalf to carry out the quotation search and will be used solely for this purpose. Your personal data will be kept confidential and will not be stored for longer than necessary. We will store the information you supply to us for the quotation search on our systems and the result will be available from Barclays at a future date. This service won't affect your ability to obtain credit in the future and will not be seen by any other lenders. Any personal information you give us is protected over the Internet using secure web server technology.
                    </p>
                    <p>
                        The pre-application check will provide you with an indicative maximum loan amount based on the information you have provided and information obtained from credit reference agencies. The amount that will be shown has been assessed as a suitable choice for you based on an initial check of your credit record and the additional information you have provided. This does not constitute an offer of finance for your purchase and you may be declined once a full assessment of your application has been completed. This scoring method only applies to Barclays Partner Finance, as other companies may use different scoring methods.
                    </p>
                </div>

                <p>
                    We (Barclays) are committed to protecting your personal information. For more detailed information on how and why we use your information, including your rights relating to your personal information and our legal rights to use it, please go to <a href="https://www.barclayspartnerfinance.com/home/privacy-policy/" target="_blank">https://www.barclayspartnerfinance.com/home/privacy-policy/ <img src="../assets/external-link-icon.svg" alt="opens in new window icon" /></a> or ask us for a copy.
                </p>
                <p>
                    For more detailed information on how credit reference agencies use your information please go to <a href="https://www.experian.co.uk/legal/crain/" target="_blank">https://www.experian.co.uk/legal/crain/ <img src="../assets/external-link-icon.svg" alt="opens in new window icon" /></a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters} from 'vuex'

    export default {
        methods: {
            close: function () {
                this.$emit('closeModal');
            }
        },
        computed: {
            ...mapGetters(['retailer'])
        },
        mounted() {
            this.$refs.closeModalButton.focus();
        }
    }

</script>

<style lang="scss" scoped>

</style>
