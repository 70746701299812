<template>
    <div class="page-container">
        <div class="page" :aria-hidden="modalShown">

            <h2 tabindex="-1" class="main-anchor">Spread the cost of your purchase with Barclays Partner Finance.</h2>

            <p>Use our Barclays' Eligibility Check to see if you are likely to be approved for finance.</p>

            <ul>
                <li>It won't affect your credit score</li>
                <li>There's no commitment</li>
                <li>It's quick and easy to complete</li>
                <li v-if="this.retailer.displayAffordabilityResults == true">Find out how much you may be able to borrow, before applying</li>
            </ul>

            <p>The Barclays Eligibility Checker uses automated decision making in order to check your eligibility. The result it provides you with is an indication only of whether you are likely to be accepted for finance and is not guaranteed.</p>

            <div v-if="retailer.content.ExtraSplashInfoShow == true">
              <p v-html="retailer.content.ExtraSplashInfoHeader"/>
              <ul>
                <li class="arrow" v-for="points in retailer.content.ExtraSplashInfoPoints.split('\\n')" v-bind:key="points">
                  {{points}}
                </li>
              </ul>
            </div>
            
            <p v-if="retailer.displayAffordabilityResults == false">Our decision is based on borrowing up to &pound;{{this.retailer.averageBorrowingAmount}} (including interest, if any), the average that customers borrow with {{this.retailer.name}} which has been assessed over a term of {{this.retailer.term}} months.</p>

            <rep-examples v-if="retailer.displayAffordabilityResults == false" />

            <p><b>Credit is subject to application, financial circumstances and borrowing history, so are the interest rates and finance terms that you may be offered.</b></p>


            <div class="eligibility-criteria">
                <img id="eligibility-criteria-icon" src="../../assets/icon-information.svg" alt="" />
                <p id="eligibility-criteria-text">
                    To apply for finance, you need to meet our <span id="eligiblityCriteriaButton" class="eligibilty-criteria-link" v-on:click="showEligibilityCriteria" @keyup.enter="showEligibilityCriteria" :tabindex="modalShown ? -1 : 0" role="button">eligibility criteria</span>
                </p>
            </div>
            <div class="button-container-inner">
                <router-link to="/eligibility" class="button primary-button-text" role="button" :tabindex="modalShown ? -1 : 0" id="continueButton">
                    Continue
                </router-link>
            </div>
        </div>

        <div class="page page-alt-background" :aria-hidden="modalShown">

            <frequently-asked-questions :phaseID="1" :faqItems="this.enums.defaultFAQItems"></frequently-asked-questions>

        </div>

        <div class="page" :aria-hidden="modalShown">
            <p>
                Please see our <a class="terms-and-conditions-link" href="https://www.barclayspartnerfinance.com/home/important-information/" :tabindex="modalShown ? -1 : 0" target="_blank">important information  <img src="../../assets/external-link-icon.svg" alt="opens in new window icon" /></a> about the use of this website and our <span class="terms-and-conditions-link" v-on:click="showPrivacyPolicy" @keydown.enter.prevent="showPrivacyPolicy" :tabindex="modalShown ? -1 : 0" role="button" id="privacyPolicyButton">privacy policy</span> for how we use your data
            </p>
        </div>

        <div class="modals">
            <eligibility-criteria v-if="modals.eligibilityCriteriaShown" @closeModal="closeModal"></eligibility-criteria>

            <terms-and-conditions v-if="modals.termsAndConditionsShown" @closeModal="closeModal"></terms-and-conditions>

        </div>
    </div>
</template>

<script>

    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import EligibilityCriteria from '../EligibilityCriteria.vue'
    import TermsAndConditions from '../TermsAndConditions.vue'
    import RepExamples from '../RepExamples.vue'
    import FrequentlyAskedQuestions from '../FAQ.vue'

    export default {
        computed: {
            ...mapGetters(['enums', 'application', 'retailer', 'modals', 'modalShown'])
        },
        methods: {
            ...mapActions(['getConfig', 'closeModal']),
            ...mapMutations(['SET_LAST_ELEMENT']),
            showTermsAndConditions: function () {
                this.modals.termsAndConditionsShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            },
            showEligibilityCriteria: function () {
                this.modals.eligibilityCriteriaShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            },
            showPrivacyPolicy: function () {
                this.modals.privacyPolicyShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            },
            showRepExamples: function () {
                this.modals.repExamplesShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            }
        },
        components: {
            'eligibility-criteria': EligibilityCriteria,
            'terms-and-conditions': TermsAndConditions,
            'rep-examples': RepExamples,
            'frequently-asked-questions': FrequentlyAskedQuestions
        },
        data() {
            return {
                eligibilityCriteriaShown: false,
                termsAndConditionsShown: false,
                repExamplesShown: false
            };
        },
        created() {
            this.getConfig({
                retailerCode: this.$route.query.retailerCode
            });

            this.modals.termsAndConditionsShown = false;
            this.eligibilityCriteriaShown = false;
        },
        mounted() {
            //this ties up the inputs to the last element functionality required for restoring focus after modal.
            let inputs = document.querySelectorAll('input, .button, [role="button"]');
            const instance = this;

            for (var i = 0, len = inputs.length; i < len; i++) {
                inputs[i].addEventListener('focus', function () {
                    let element = this;

                    instance.SET_LAST_ELEMENT({
                        lastElement: element.id
                    });
                })
            }
        }
    }

</script>

<style lang="scss" scoped>
    .eligibility-criteria {
        background: $background-tooltip-hero;
        border: 2px solid $branding;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 14px;

        @include bp(tablet) {
            padding-left: 30px;
        }
    }

    li {
        list-style: none;
        &::before

    {
        content: "";
        width: 24px;
        height: 24px;
        background-image: url(~@/assets/icon-tick.svg);
        position: relative;
        display: inline-block;
        top: 5px;
        left: -8px;
    }

    }

    li.arrow {
      list-style: none;
      &::before

      {
        content: "";
        width: 30px;
        height: 30px;
        background-image: url(~@/assets/icon-arrow.svg);
        position: relative;
        display: inline-block;
        margin-top: -3px;
        margin-right: -9px;
        top: 7px;
        left: -10px;
      }

    }

    .eligibilty-criteria-link, .rep-examples-link {
        color: $interactive;
        text-decoration: underline;
        cursor: pointer;
    }

    ul {
        @include bp(mobile) {
            padding-left: 10px;
        }
    }

    #eligibility-criteria-icon {
        float: left;
        margin-right: 10px;
    }

    #eligibility-criteria-text {
        overflow: hidden;
        margin-bottom: 0;
    }
</style>