export class Address {
    constructor() {
        this.id = null;
        this.houseNumber = null;
        this.houseName = null;
        this.flatNumber = null;
        this.addressLine1 = null;
        this.townCity = null;
        this.postcode = null;
        this.picklist = null;
        this.dateMovedToAddress = '';
        this.addressFound = false;
        this.showPostcodeLookup = true;
        this.showPicklist = false;
        this.showManualEntry = false;
        this.showDateMovedToAddress = false;
        this.showAddAddress = false;
        this.showSummary = false;
        this.showSummaryTop = false;
        this.showSummaryBottom = false;
        this.selectedAddress = '';
        this.resultType = null;
        this.showAddressWarning = false;
        this.nextAddressAdded = false;
        this.addressSearched = false;
        this.addressConfirmed = false;
        this.addressSearchedWarning = false;
        this.addressConfirmedWarning = false;
        this.updateDateMovedToAddress = null;
    }
}

export class Application {
    constructor() {
        this.title = '';
        this.firstName = null;
        this.middleName = null;
        this.lastName = null;
        this.dateOfBirth = '';
        this.employmentStatus = '';
        this.grossAnnualIncome = '';
        this.residentialStatus = '';
        this.incomeToDecrease = null;
        this.spendToIncrease = null;
        this.newGrossAnnualIncome = null;
        this.monthlySpendIncrease = '';
        this.childcareCost = null;
        this.addresses = [
            new Address()
        ]
    }
}

export class Affordability {
    constructor() {
        this.incomeToDecrease = null;
        this.spendToIncrease = null;
        this.newGrossAnnualIncome = '';
        this.monthlySpendIncrease = '';
        this.childcareCost = '';
        this.dependants = '';
        this.dependantsCheck = null;
    }
}