<template v-if="this.xsrfToken">
    <div>
        <page-header></page-header>

        <router-view></router-view>

        <page-footer></page-footer>
    </div>
</template>
<style lang="scss">
@import '../node_modules/hd-styles/modules/mixins';

</style>
<script>

import PageHeader from './components/HeaderView.vue'
import PageFooter from './components/FooterView.vue'
import { mapActions } from "vuex"

export default {
  name: 'App',
  components: {
      'page-header': PageHeader,
      'page-footer': PageFooter
        },
       computed: {
            ...mapActions(["generateXSRFToken"])
        },
        mounted() {
            this.generateXSRFToken;
        }
}
</script>