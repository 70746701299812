<template>
    <div class="modal-background" @click.self.prevent="close">
        <div class="modal-container">

            <span class="close-modal" ref="closeModal" @click.self.prevent="close" @keydown.enter.prevent="close" tabindex="0" aria-label="close dialog" role="button">x</span>

            <div class="modal-container-inner modal-scrolling">
                <div class="modal-header">
                    <h2>Pre-application Check Terms and Conditions</h2>
                </div>

                <div>
                    <p>
                        By completing and submitting this form, your personal data will be used to make a suitable credit suggestion for you.
                        Your details will be shared with the relevant credit reference agencies and companies acting on our behalf to carry out the quotation search
                        and will be used to help us make financial decisions for you in the future. Your personal data will be kept confidential and will not be stored for longer than necessary.
                        We will store the information you supply to use for the quotation search on our systems and the result will be available from Barclays at a future date.
                        This service might affect your ability to obtain credit in the future but will not be seen by any other lenders.
                        Any personal information you give us is protected over the Internet using secure web server technology.
                    </p>
                    <p>
                        The pre-application check will provide you with an indicative amount of credit based on the information you have provided, information from credit reference agencies and your borrowing history with Barclays.
                        The amount that will be shown has been assessed as suitable choice for you based on a high-level check of your credit record and the additional information you have provided.
                        This does not constitute an offer of credit and you may be declined once a full assessment of your application has been completed.
                        This scoring method only applies to Barclays, as other companies may use different scoring methods.
                    </p>
                    <p>
                        We (Barclays) are committed to protecting your personal information. For more detailed information on how and why we use your information, including your rights relating to your personal information and our legal rights to use it, please go to <a href="https://www.barclayspartnerfinance.com/home/privacy-policy/" target="_blank">https://www.barclayspartnerfinance.com/home/privacy-policy/</a> or ask us for a copy.
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    export default {
        methods: {
            close: function () {
                this.$emit('closeModal');
            }
        },
        mounted() {
            this.$refs.closeModal.focus();
        }
    }

</script>

<style lang="scss" scoped>
</style>
