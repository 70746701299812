<template>
    <div class="address-header-container">
        <div class="address-header-left">
            <h4 v-if="addressIndex == 0">
                Current UK Address
            </h4>
            <h4 v-if="addressIndex > 0">
                Previous UK Address {{addressIndex}}
            </h4>
        </div>

        <div class="address-header-right" v-if="address.showManualEntry">
            <span :id="'address[' + addressIndex + '].deleteAddressButton'" class="delete-address" @click.prevent="deleteAddress" @keydown.enter.prevent="deleteAddress" :tabindex="modalShown ? -1 : 0" role="button">Delete</span>
            <span :id="'address[' + addressIndex + '].deleteAddressButtonDesktop'" class="delete-address-desktop" @click.prevent="deleteAddress" @keydown.enter.prevent="deleteAddress" :tabindex="modalShown ? -1 : 0" role="button">Delete this address</span>
        </div>

    </div>

    <div class="qas-error-container" v-if="showPostcodeError">
        <div class="qas-error-header">
            <h4>Service unavailable</h4>
        </div>
        <p>Sorry - our postcode lookup service isn't working right now. Please enter your address manually instead.</p>
    </div>

    <div :class="{'form-row':true, 'is-valid': !houseNumberError && isHouseNumberTouched}">
        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].houseNumber'" :id="'address[' + addressIndex + '].houseNumberLabel'">House or building number <i>(Required)</i></label>
        </div>

        <div class="form-row-control">
            <input :id="'address[' + addressIndex + '].houseNumber'"
                   :name="'address[' + addressIndex + '].houseNumber'"
                   v-model="houseNumberValue"
                   @blur="houseNumberHandleBlur; validateHouseNumber(); isHouseNumberTouched=true"
                   @input="value => address.houseNumber = value.currentTarget.value"
                   :aria-describedby="'address[' + addressIndex + '].houseNumberErrors'"
                   :tabindex="modalShown ? -1 : 0"
                   :ref="'address[' + addressIndex + '].houseNumberInput'"
                   :aria-invalid="houseNumberError? true : false"
                   :class="{'invalid': houseNumberError, 'valid': !houseNumberError && houseNumberMeta.touched}"/>
        </div>

        <span :class="{'invalid-text': houseNumberError, 'form-row-validation':true }" v-if="houseNumberError" :id="'address[' + addressIndex + '].houseNumberErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ houseNumberError }}</span>
        </span>
    </div>

    <div :class="{'form-row':true, 'is-valid': !flatNumberError && isFlatNumberTouched}">

        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].flatNumber'" :id="'address[' + addressIndex + '].flatNumberLabel'">Flat number <i>(Optional)</i></label>
        </div>

        <div class="form-row-control">
            <input :id="'address[' + addressIndex + '].flatNumber'"
                   :name="'address[' + addressIndex + '].flatNumber'"
                   v-model="flatNumberValue"
                   @blur="flatNumberHandleBlur; validateFlatNumber(); isFlatNumberTouched=true"
                   :aria-describedby="'address[' + addressIndex + '].flatNumberErrors'"
                   :tabindex="modalShown ? -1 : 0"
                   :aria-invalid="flatNumberError ? true : false" @input="value => address.flatNumber = value.currentTarget.value"
                   :class="{'invalid': flatNumberError, 'valid': !flatNumberError && flatNumberMeta.touched}"/>
        </div>

        <span :class="{'invalid-text': flatNumberError, 'form-row-validation':true }" v-if="flatNumberError" :id="'address[' + addressIndex + '].flatNumberErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ flatNumberError }}</span>
        </span>
    </div>

    <div :class="{'form-row':true, 'is-valid': !houseNameError && isHouseNameTouched}">

        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].houseName'" :id="'address[' + addressIndex + '].houseNameLabel'">House or building name <i>(Required)</i></label>
        </div>

        <div class="form-row-control">
            <input :id="'address[' + addressIndex + '].houseName'"
                   :name="'address[' + addressIndex + '].houseName'"
                   v-model="houseNameValue"
                   @input="value => address.houseName = value.currentTarget.value"
                   @blur="houseNameHandleBlur; validateHouseName(); isHouseNameTouched=true"
                   :aria-describedby="'address[' + addressIndex + '].houseNameErrors'"
                   :tabindex="modalShown ? -1 : 0"
                   :aria-invalid="houseNameError ? true : false"
                   :class="{'invalid': houseNameError, 'valid': !houseNameError && houseNameMeta.touched}"/>
        </div>

        <span :class="{'invalid-text': houseNameError, 'form-row-validation':true }" v-if="houseNameError" :id="'address[' + addressIndex + '].houseNameErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ houseNameError }}</span>
        </span>
    </div>

    <div :class="{'form-row':true, 'is-valid': !addressLine1Error && addressLine1Meta.dirty}">

        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].addressLine1'" :id="'address[' + addressIndex + '].addressLine1Label'">Address line 1 <i>(Required)</i></label>
        </div>

        <div class="form-row-control">
            <input :id="'address[' + addressIndex + '].addressLine1'"
                   :name="'address[' + addressIndex + '].addressLine1'"
                   autocomplete="address-line1"
                   v-model="addressLine1Value"
                   @input="value => address.addressLine1 = value.currentTarget.value"
                   @blur="addressLine1HandleBlur; validateAddressLine1()"
                   :aria-describedby="'address[' + addressIndex + '].addressLine1Errors'"
                   :tabindex="modalShown ? -1 : 0"
                   :aria-invalid="addressLine1Error ? true : false"
                   :class="{'invalid': addressLine1Error, 'valid': !addressLine1Error && addressLine1Meta.touched}"/>
        </div>

        <span :class="{'invalid-text': addressLine1Error, 'form-row-validation':true }" v-if="addressLine1Error" :id="'address[' + addressIndex + '].addressLine1Errors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ addressLine1Error }}</span>
        </span>
    </div>

    <div :class="{'form-row':true, 'is-valid': !townCityError && townCityMeta.dirty}">

        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].townCity'" :id="'address[' + addressIndex + '].townCityLabel'">Town/City <i>(Required)</i></label>
        </div>

        <div class="form-row-control">
            <input :id="'address[' + addressIndex + '].townCity'"
                   :name="'address[' + addressIndex + '].townCity'"
                   autocomplete="address-level1"
                   v-model="townCityValue"
                   @input="value => address.townCity = value.currentTarget.value"
                   @blur="townCityHandleBlur; validateTownCity()"
                   :aria-describedby="'address[' + addressIndex + '].townCityErrors'"
                   :tabindex="modalShown ? -1 : 0"
                   :aria-invalid="townCityError ? true : false"
                   :class="{'invalid': townCityError, 'valid': !townCityError && townCityMeta.touched}"/>
        </div>

        <span :class="{'invalid-text': townCityError, 'form-row-validation':true }" v-if="townCityError" :id="'address[' + addressIndex + '].townCityErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ townCityError }}</span>
        </span>
    </div>

    <div :class="{'form-row':true, 'is-valid': !postcodeError && postcodeMeta.dirty}">

        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].postcode'" :id="'address[' + addressIndex + '].postcodeLabel'">Postcode <i>(Required)</i></label>
        </div>

        <div class="form-row-control">
            <input :id="'address[' + addressIndex + '].postcode'"
                   :name="'address[' + addressIndex + '].postcode'"
                   v-model="postcodeValue"
                   @input="value => address.postcode = value.currentTarget.value"
                   @blur="postcodeHandleBlur; validatePostcode()"
                   autocomplete="postal-code"
                   :aria-describedby="'address[' + addressIndex + '].postcodeErrors'"
                   :tabindex="modalShown ? -1 : 0"
                   :aria-invalid="postcodeError ? true : false"
                   :class="{'invalid': postcodeError, 'form-row-postcode': true,  'valid': !postcodeError && postcodeMeta.touched}"/>
        </div>

        <span :class="{'invalid-text': postcodeError, 'form-row-validation':true }" v-if="postcodeError" :id="'address[' + addressIndex + '].postcodeErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ postcodeError }}</span>
        </span>
    </div>

    <span :id="'address[' + addressIndex + '].postcodeLookupButton'" class="use-postcode-lookup" @click.prevent="showPostcodeLookup" @keydown.enter.prevent="showPostcodeLookup" :tabindex="modalShown ? -1 : 0" role="button">Use postcode lookup</span>
</template>

<script setup>
    import { defineProps, ref, toRef, computed, defineEmits, nextTick } from 'vue'
    import { useStore } from 'vuex'
    import { useField, configure } from 'vee-validate'
    import { localize } from '@vee-validate/i18n'

    const props = defineProps({
        address: {
            type: Object,
            required: true
        },
        addressIndex: {
            type: Number,
            required: true
        },
        maxAddresses: {
            type: Number,
            required: true
        }
    })

    /* eslint-disable no-unused-vars */
    const emit = defineEmits(['deleteAddress'])
    const isHouseNameTouched = ref(false)
    const isHouseNumberTouched = ref(false)
    const isFlatNumberTouched = ref(false)
    const searchingAddress = ref(false)
    const showPostcodeError = ref(false)
    const address = toRef(props.address)
    const addressIndex = toRef(props.addressIndex)
    const maxAddresses = toRef(props.maxAddresses)
    const store = useStore()

    /* eslint-disable no-unused-vars */
    const modalShown = computed(() => store.getters.modalShown)

    // Validation
    const { value: houseNumberValue, errorMessage: houseNumberError, meta: houseNumberMeta, handleBlur: houseNumberHandleBlur, validate: houseNumberValidate } = useField(`address[${addressIndex.value}].houseNumber`, `one_of_required:@address[${addressIndex.value}].houseName|start_with_number|alpha_special_unlimited`, { keepValueOnUnmount: true, initialValue: null })
    const { value: flatNumberValue, errorMessage: flatNumberError, meta: flatNumberMeta, handleBlur: flatNumberHandleBlur, validate: flatNumberValidate } = useField(`address[${addressIndex.value}].flatNumber`, 'alpha_special_unlimited|start_with_number', { keepValueOnUnmount: true, initialValue: null })
    const { value: houseNameValue, errorMessage: houseNameError, meta: houseNameMeta, handleBlur: houseNameHandleBlur, validate: houseNameValidate } = useField(`address[${addressIndex.value}].houseName`, `alpha_special_unlimited|one_of_required:@address[${addressIndex.value}].houseNumber|only_numbers|min:2|start_with_letter_or_number`, { keepValueOnUnmount: true, initialValue: null })
    const { value: addressLine1Value, errorMessage: addressLine1Error, meta: addressLine1Meta, handleBlur: addressLine1HandleBlur, validate: addressLine1Validate } = useField(`address[${addressIndex.value}].addressLine1`, 'required|min:2|alpha_special_unlimited_ampersand|start_with_letter_or_number|at_least_two_alpha', { keepValueOnUnmount: true })
    const { value: townCityValue, errorMessage: townCityError, meta: townCityMeta, handleBlur: townCityHandleBlur, validate: townCityValidate } = useField(`address[${addressIndex.value}].townCity`, 'required|min:2|alpha_special_unlimited_ampersand|start_with_letter|at_least_two_alpha', { keepValueOnUnmount: true })
    const { value: postcodeValue, errorMessage: postcodeError, meta: postcodeMeta, handleBlur: postcodeHandleBlur, validate: postcodeValidate } = useField(`address[${addressIndex.value}].postcode`, 'required|postcode|min:6|max:8', { keepValueOnUnmount: true })

    async function validateHouseNumber() {
        await houseNumberValidate()
    }

    async function validateFlatNumber() {
        await flatNumberValidate()
    }

    async function validateHouseName() {
        await houseNameValidate()
    }

    async function validateAddressLine1() {
        await addressLine1Validate()
    }

    async function validateTownCity() {
        await townCityValidate()
    }

    async function validatePostcode() {
        await postcodeValidate()
    }

    configure({
        generateMessage: localize('en', {
            fields: {
                [`address[${addressIndex.value}].houseNumber`]: {
                    one_of_required: `Please enter either your house number or house name.`,
                    start_with_number: `Please enter a valid house number. The first character must be a number.`,
                    alpha_special_unlimited: `Please enter a valid house number`,
                    start_with_letter_or_number: `Please enter your house name, beginning with a letter or a number.`,
                },
                [`address[${addressIndex.value}].flatNumber`]: {
                    alpha_special_unlimited: `Please enter a valid flat or apartment number.`,
                    start_with_number: `Please enter your flat or apartment number, beginning with a number.`
                },
                [`address[${addressIndex.value}].houseName`]: {
                    one_of_required: `Please enter either your house number or house name.`,
                    only_numbers: `Your house name must include letters. If you've entered your house number here, please enter it in the 'House number' field instead.`,
                    alpha_special_unlimited: `Please enter a valid house name.`,
                    min: `Please enter your house name in full. A single character is not permitted.`,
                    start_with_letter_or_number: `Please enter your house name, beginning with a letter or a number.`
                },
                [`address[${addressIndex.value}].addressLine1`]: {
                    required: `Please enter the first line of your address (for example, your street name).`,
                    min: `Please enter the first line of your address in full. A single character is not permitted.`,
                    alpha_special_unlimited_ampersand: `You've entered an invalid first line for your address. Please check and try again.`,
                    start_with_letter_or_number: `Please enter the first line of your address, beginning with a letter or a number.`,
                    at_least_two_alpha: `Please enter the first line of your address in full. This must contain at least two letters.`
                },
                [`address[${addressIndex.value}].townCity`]: {
                    required: `Please enter your town or city.`,
                    min: `Please enter your town or city in full. A single character is not permitted.`,
                    alpha_special_unlimited_ampersand: `Please enter a valid town or city.`,
                    start_with_letter: `Please enter your town or city in full, beginning with a letter.`,
                    at_least_two_alpha: `Please enter your town or city in full. A single letter is not permitted.`
                },
                [`address[${addressIndex.value}].postcode`]: {
                    required: `Please enter your UK postcode in full.`,
                    postcode: `This is not a valid postcode. Please check and try again.`,
                    min: `This is not a valid postcode. Please check and try again.`,
                    max: `This is not a valid postcode. Please check and try again.`
                },
                [`address[${addressIndex.value}].timeAtAddressMonths`]: {
                    required_date: `Please enter the date you moved to the address.`,
                    required: `Please enter the date you moved to the address.`,
                    not_in_future: `The year you've entered is in the future. Please check and try again.`,
                    date_before: `Please enter a date earlier than the previous address move in date.`,
                    date: `Please enter a valid date.`,
                    less_than_years: `The year you've entered is invalid. Please check and try again.`,
                    valid_month: `Please enter a valid month (between 1 - 12).`,
                    valid_year: `Please enter a valid year.`
                }

            },
        }),
    })

    if (address.value.addressConfirmed) {
        houseNumberValue.value = address.value.houseNumber
        flatNumberValue.value = address.value.flatNumber
        houseNameValue.value = address.value.houseName
        addressLine1Value.value = address.value.addressLine1
        townCityValue.value = address.value.townCity
        postcodeValue.value = address.value.postcode
    }

    /* eslint-disable no-unused-vars */

    function showPostcodeLookup() {
        address.value.showPostcodeLookup = true
        address.value.showPicklist = true
        address.value.showManualEntry = false
        address.value.showDateMovedToAddress = false
        address.value.showSummary = false
        address.value.addressConfirmed = false
        address.value.selectedAddress = ''

        //for accessibility, focus the top new element
        nextTick(() => {
            document.getElementById(`address[${addressIndex.value}].postcodeLookup`).focus()
        })
    }

    function deleteAddress() {
        emit('deleteAddress', address.value)
    }
</script>

<style lang="scss" scoped>

    @import '../../node_modules/hd-styles/modules/mixins';

    .delete-address, .delete-address-desktop {
        text-decoration: underline;
        color: $interactive;
        cursor: pointer;
    }

    .delete-address {
        @include bp(tablet) {
            display: none;
        }
    }

    .delete-address-desktop {
        display: none;

        @include bp(tablet) {
            display: inline-block;
        }
    }

    .use-postcode-lookup {
        display: inline-block;
        float: none;
        padding-left: 0;
        padding-top: 20px;
        cursor: pointer;
        text-decoration: underline;
        color: $interactive;
        clear: both;
        width: auto;
        white-space: nowrap;
    }

    .address-header h5, .address-header-left h5 {
        margin-top: 10px;
    }

    .address-header-left {
        width: 50%;
        display: inline-block;
    }

    .address-header-right {
        float: right;
        display: inline-block;
        margin-top: 10px;
    }

    .form-row-postcode {
        @include grid-size(calc(24/24));
        text-transform: uppercase;
        padding-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(5/24));
            padding-left: 0;
        }
    }

    .find-address {
        margin-top: 30px;

        @include bp(tablet) {
            margin-top: -9px;
            margin-left: 10px;
        }
    }
</style>
