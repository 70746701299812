<template>
    <div class="page">
        <h2 tabindex="-1" class="main-anchor">Good news</h2>
        <p>Based on our initial checks you are likely to be approved for finance.</p>
        <p>Unfortunately we were unable to verify your income to determine how much you are likely to be able to borrow.</p>
        <p>We have based this eligibility decision on the average loan and term for {{retailer.name}}.</p>
        <span class="show-decision-details" v-on:click="toggleDecisionDetails">&gt;more information on how we made this decision</span>
        <div class="body" v-if="showDecisionDetails">
            <p>We have based your eligibility on borrowing up to &pound;{{this.retailer.averageBorrowingAmount}} (including interest, if any), the average that customers borrow with {{retailer.name}} with a monthly repayment amount of &pound;{{(this.retailer.averageBorrowingAmount/this.retailer.term).toLocaleFixed(2)}} per month.</p>
        </div>
        <p>If you choose to proceed then we may ask you to provide copies of recent payslips or bank statements.</p>

        <div class="body" v-show="showSection">
            <img v-bind:src="imageUrl">
        </div>

        <hr aria-hidden="true" />

        <h2>Important information</h2>
        <ul>
            <li v-for="information in retailer.content.ImportantInformation.split('\\n')" v-bind:key="information">
              {{information}}
            </li>
        </ul>
        <br />

        <rep-examples subHeading="This representative example is based on a typical finance agreement and does not affect the maximum amount of finance described above" />

        <p><b>Credit is subject to application, financial circumstances and borrowing history, so are the interest rates and finance terms that you may be offered.</b></p>
        <hr aria-hidden="true" />

        <div class="how-to-apply">
            <h2>How to apply</h2>
            <p>To pay using finance, first decide which item(s) you wish to purchase. Then at the checkout, choose to pay with Barclays Partner Finance.</p>
        </div>

        <click-out-link v-if="retailer.clickoutLink"></click-out-link>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ClickOut from './ClickOut.vue'
    import RepExamples from './RepExamples.vue'

    export default {
        computed: {
            ...mapActions(['closeModal']),
            ...mapGetters(['retailer', 'results', 'modals', 'modalShown'])
        },
        props: {
            phaseID: {
                type: Number,
                required: true
            }
        },
        components: {
            'click-out-link': ClickOut,
            'rep-examples': RepExamples
        },
        data() {
            return {
                showDecisionDetails: false
            };
        },
        methods: {
            toggleDecisionDetails() {
                this.showDecisionDetails = !this.showDecisionDetails
            }
        }
    }

</script>

<style lang="scss" scoped>

    .headline-offer {
        background-color: $background2;
        text-align: center;
        border-radius: 5px;
        margin-bottom: 20px;

        &-inner

    {
        width: 50%;
        margin: 0 auto;
        padding: 10px 0;
    }

    }

    .headline-offer-text {
        margin: 0.25rem;
    }

    .headline-offer-number {
        color: $interactive;
        margin-bottom: 0px;
    }

    .headline-offer-disclosure {
        font-size: 12px;
    }

    .how-to-apply {
        background-color: $background-tooltip-hero;
        border: 2px solid $branding;
        border-radius: 5px;
        padding: 20px 30px;
        margin-bottom: 30px;
    }

    hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .rep-examples-link, .show-decision-details {
        color: $interactive;
        text-decoration: underline;
        cursor: pointer;
    }
</style>