import { createRouter, createWebHistory } from 'vue-router'
import store from '../vuex/store';
import SplashView from '../components/pages/SplashView.vue';
import EligibilityView from '../components/pages/EligibilityView.vue';
import ResultsPage from '../components/pages/Results.vue';
import ErrorPage from '../components/pages/Error.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [

        {
            path: '/',
            component: SplashView,
            beforeEnter: (to, from, next) => {
                if (!to.query.retailerCode) {
                    next('/error');
                }
                else {
                    next();
                }
            },
            meta: { title: 'Barclays Partner Finance' }
        },
        {
            path: '/eligibility',
            component: EligibilityView,
            beforeEnter: (to, from, next) => {
                if (!store.getters.retailer.name) {
                    next('/error');
                }
                else {
                    next();
                }
            },
            meta: { title: 'Barclays Partner Finance - Eligibility' }
        },        
        {
            path: '/results',
            name: 'Results',
            component: ResultsPage,
            beforeEnter: (to, from, next) => {
                if (isNaN(store.getters.enums.resultType) || store.getters.enums.resultType === null || store.getters.enums.resultType == 4) {
                    next('/error');
                }
                else {
                    next();
                }
            },
            meta: { title: 'Barclays Partner Finance - Results' }
        },
        {
            path: '/error',
            component: ErrorPage,
            meta: { title: 'Barclays Partner Finance - Error' }
        },
        {
            path: '/*',
            name: 'Error',
            component: ErrorPage,
            meta: { title: 'Barclays Partner Finance - Error' }
        }
    ]
})
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    store.dispatch('saveSessionEvent', {
        retailerCode: store.getters.retailer.retailerCode || to.query.retailerCode,
        eventType: 'Route to: ' + to.name
    }).then(() => next());


});

export default router
