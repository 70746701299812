<template>
    <div class="page">

        <div class="error-notification-container">
            <div class="notification-icon" />
            <p>Sorry - something went wrong</p>
        </div>

        <p>Sorry, something has gone wrong. We're trying to fix the issue. Your credit rating has not been affected. Please try again later. To continue with your shopping, please close this window.</p>

        <click-out-link v-if="retailer.clickoutLink"></click-out-link>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import ClickOut from '../ClickOut.vue'
    export default {
        computed: {
            ...mapGetters(['retailer']),
        },
        created() {
        },
        components: {
            'click-out-link': ClickOut,
        }
    }

</script>


<style lang="scss" scoped>

    .error-notification-container {
        background-color: $background2;
        border: $outline 1px solid;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }

    .notification-icon {
        width: 30px;
        height: 30px;
        background-image: url(~@/assets/icon-information.svg);
        background-repeat: no-repeat;
        margin: 10px auto;
    }
</style>