<template>
    <div @keyup.capture="updateValue">

        <div class="form-row-label">
            <label id="dateOfBirthLabel">Date of birth <i>(Expected format of DD, MM, YYYY. Required)</i></label>
        </div>

        <div class="form-row-control input-day">
            <input v-if="showDay"
                   :class="{'invalid': hasErrors, 'number-field':true, 'valid': !hasErrors && isValid }"
                   :value="day"
                   @blur="pad('day', day, 2); updateValue()"
                   @input="value => day = updateInputValue(value.currentTarget.value, 2)"
                   id="dateOfBirth"
                   name="dateOfBirthDay"
                   aria-label="Date of birth day in the format DD. Required"
                   aria-describedby="dateOfBirthErrors"
                   type="number"
                   inputmode="numeric"
                   :tabindex="modalShown ? -1 : 0" />
        </div>

        <span v-if="showDay && showMonth"
              class="date-divider">/</span>

        <div class="form-row-control input-month">
            <input v-if="showMonth"
                   :class="{'invalid': hasErrors, 'valid': !hasErrors && isValid}"
                   :value="month"
                   @blur="pad('month', month, 2); updateValue()"
                   @input="value => month = updateInputValue(value.currentTarget.value, 2)"
                   id="dateOfBirthMonth"
                   name="dateOfBirthMonth"
                   aria-label="Date of birth month in the format MM. Required"
                   aria-describedby="dateOfBirthErrors"
                   type="number"
                   inputmode="numeric"
                   :tabindex="modalShown ? -1 : 0" />
        </div>

        <span v-if="showYear && (showDay || showMonth)"
              class="date-divider">/</span>

        <div class="form-row-control input-year">
            <input v-if="showYear"
                   :class="{'invalid': hasErrors, 'valid': !hasErrors && isValid}"
                   :value="year"
                   @blur="updateValue()"
                   @input="value => year = updateInputValue(value.currentTarget.value, 4)"
                   id="dateOfBirthYear"
                   name="dateOfBirthYear"
                   aria-label="Date of birth year in the format YYYY. Required"
                   aria-describedby="dateOfBirthErrors"
                   type="number"
                   inputmode="numeric"
                   :tabindex="modalShown ? -1 : 0" />
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import { mapGetters } from 'vuex'

    export default {
        name: 'FormDate',
        props: {
            value: {
                type: [Number, String]
            },
            showDay: {
                type: Boolean,
                default: true,
            },
            showMonth: {
                type: Boolean,
                default: true,
            },
            showYear: {
                type: Boolean,
                default: true,
            },
            hasErrors: {
                type: Boolean,
                required: true
            },
            isValid: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                day: `${this.value ? moment(this.value, 'DD-MM-YYYY').date() : ''}`,
                month: `${this.value ? moment(this.value, 'DD-MM-YYYY').month() + 1 : ''}`,
                year: `${this.value ? moment(this.value, 'DD-MM-YYYY').year() : ''}`,
                markedCompleted: false
            };
        },
        computed: {
            ...mapGetters(['modals', 'modalShown'])
        },
        methods: {
            updateValue() {

                    if (this.day.length == 2 && this.month.length == 2 && this.year.length == 4) {
                        this.markedCompleted = true;
                    }

                    if (this.markedCompleted) {
                        let dateString = this.day + "-" + this.month + "-" + this.year;
                        this.$emit('dateofbirth', dateString);
                        this.$emit('input', dateString);
                    }
            },
            updateInputValue(value, max) {
                this.$forceUpdate();
                let newValue = value.toString().substring(0, max);
                return newValue;
            },
            pad(dataObject, stringToPad, maxLength) {
                if (stringToPad == '') {
                    return stringToPad;
                }
                else {
                    if (stringToPad.length < maxLength) {
                        let newValue = this.padZero(stringToPad, maxLength);
                        this[dataObject] = newValue;
                    }
                }
            },
            padZero(value, maxLength) {
                const padChar = '0';
                while (value.length < maxLength) value = padChar + value;
                return value;
            }
        },
        created() {
            this.pad("day", this.day, 2);
            this.pad("month", this.month, 2);

            if (this.day.length == 2 && this.month.length == 2 && this.year.length == 4) {
                this.markedCompleted = true;
            }
        }
    };
</script>

<style lang="scss" scoped>

    @import '../../node_modules/hd-styles/modules/mixins';

    .input-day {
        @include grid-size(calc(6/24));
        margin-left: 0;
        padding-left: 0;
        padding-right: 10px;

        @include bp(tablet) {
            @include grid-size(calc(3/24));
            padding-left: 0px;
            margin-left: 0;
        }

        & input

    {
        width: 100%;

    }


    &::after {
        position: absolute;
    }

    }

    .input-month {
        @include grid-size(calc(6/24));
        @include grid-push(calc(2/24));
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(3/24));
            padding-left: 10px;
            margin-left: 0;
        }

        & input

    {
        width: 100%;
    }

    &::after {
        position: absolute;
    }
    }

    .input-year {
        @include grid-size(calc(9/24));
        @include grid-push(calc(2/24));
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(4/24));
            padding-left: 10px;
            margin-left: 0;
        }

        & input

    {
        width: 100%;
    }

    &::after {
        position: absolute;
    }
    }

    .date-divider {
        float: left;
        line-height: 35px;

        @include bp(tablet) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
</style>
