<template>
    <div class="address-header">
        <h4 v-if="addressIndex == 0">
            Current UK Address
        </h4>
        <h4 v-if="addressIndex > 0">
            Previous UK Address {{addressIndex}}
        </h4>
    </div>

    <div :class="{'form-row':true, 'is-valid': !postcodeLookupError && postcodeLookupMeta.dirty}">

        <div class="form-row-label">
            <label :for="'address[' + addressIndex + '].postcodeLookup'" :id="'address[' + addressIndex + '].postcodeLookupLabel'">Postcode <i>(Required)</i></label>
        </div>

        <div class="form-row-control form-row-postcode">
            <input :id="'address[' + addressIndex + '].postcodeLookup'"
                   :name="'address[' + addressIndex + '].postcodeLookup'"
                   v-model="postcodeLookupValue"
                   @input="value => address.postcode = value.currentTarget.value"
                   @blur="postcodeLookupHandleBlur; validatePostcodeLookup()"
                   autocomplete="postal-code"
                   :aria-describedby="'address[' + addressIndex + '].postcodeLookupErrors'"
                   :class="{'invalid': postcodeLookupError,  'valid': !postcodeLookupError && postcodeLookupMeta.touched}"
                   :tabindex="modalShown ? -1 : 0"
                   :ref="'address[' + addressIndex + '].postcodeProviderInput'"
                   :aria-invalid="postcodeLookupError ? true : false" />
        </div>

        <div class="button-container-inner search-postcode">
            <button :class="searchingAddress ? 'disabledButton' : ''"
                    :aria-disabled="searchingAddress"
                    @click.prevent="searchAddress"
                    @keydown.enter="searchAddress"
                    class="button secondary-button-text float-left find-address"
                    :tabindex="modalShown ? -1 : 0"
                    type="button"
                    :id="'address[' + addressIndex + '].findAddressButton'"
                    aria-label="Find address">
                {{searchingAddress ? `Loading...` : `Find address`}}
            </button>
        </div>

        <span v-if="address.addressSearchedWarning && !postcodeLookupError" class="form-row-validation additional-error">
            <span class="error-bold">Error: </span><span>Please find your address.</span>
        </span>
        <span :class="{'invalid-text': postcodeLookupError, 'form-row-validation':true }" v-if="postcodeLookupError" :id="'address[' + addressIndex + '].postcodeLookupErrors'" aria-live="polite">
            <span class="error-bold">Error: </span><span>{{ postcodeLookupError }}</span>
        </span>
        <span v-if="!address.showPicklist" :id="'address[' + addressIndex + '].manualEntryButton'" class="manual-entry-label" @click.prevent="manualEntry" @keydown.enter.prevent="manualEntry" :tabindex="modalShown ? -1 : 0" role="button">Enter your address manually</span>
    </div>
</template>

<script setup>
    import { defineProps, ref, toRef, defineEmits, nextTick, computed } from 'vue'
    import { useStore } from 'vuex'
    import { useField, configure } from 'vee-validate'
    import { localize } from '@vee-validate/i18n'
    /* eslint-disable no-unused-vars */

    const props = defineProps({
        address: {
            type: Object,
            required: true
        },
        addressIndex: {
            type: Number,
            required: true
        }
    })

    const emit = defineEmits(['deleteAddress'])
    const searchingAddress = ref(false)
    const showPostcodeError = ref(false)
    const address = toRef(props.address)
    const addressIndex = toRef(props.addressIndex)
    const store = useStore()
    const resolveAddress = address => store.dispatch('resolveAddress', address)
    const modalShown = computed(() => store.getters.modalShown)

    const { value: postcodeLookupValue, errorMessage: postcodeLookupError, meta: postcodeLookupMeta, handleBlur: postcodeLookupHandleBlur, validate: postcodeLookupValidate } = useField(`address[${addressIndex.value}].postcodeLookup`, 'required|postcode|min:6|max:8')

    configure({
        generateMessage: localize('en', {
            fields: {
                [`address[${addressIndex.value}].postcodeLookup`]: {
                    required: `Please enter your UK postcode in full.`,
                    postcode: `This is not a valid postcode. Please check and try again.`,
                    min: `This is not a valid postcode. Please check and try again.`,
                    max: `This is not a valid postcode. Please check and try again.`
                }
            },
        }),
    })

    async function validatePostcodeLookup() {
        await postcodeLookupValidate()
    }

    function manualEntry() {
        address.value.showPostcodeLookup = false
        address.value.showPicklist = false
        address.value.showManualEntry == true ? address.value.showManualEntry = false : address.value.showManualEntry = true;
        address.value.showDateMovedToAddress = true
        address.value.showSummary = false

        //for accessibility, focus the top new element
        nextTick(() => {
            document.getElementById(`address[${addressIndex.value}].houseNumber`).focus()
        })
    }

    async function searchAddress() {
        const validationResponse = await postcodeLookupValidate()

        if (validationResponse.valid) {
            searchingAddress.value = true;

            resolveAddress({
                postcode: address.value.postcode,
                addressIndex: addressIndex.value
            }).then(() => {

                searchingAddress.value = false
                address.value.addressSearched = true
                address.value.addressSearchedWarning = false

                switch (address.value.resultType) {
                    default:
                        //assume error
                        showPostcodeError.value = true
                        address.value.showManualEntry = true
                        address.value.showPostcodeLookup = false
                        address.value.showDateMovedToAddress = true
                        break
                    case 0:
                        address.value.showManualEntry = true
                        address.value.showPostcodeLookup = false
                        address.value.showDateMovedToAddress = true
                        break
                    case 1:
                        address.value.showPicklist = true
                        address.value.showManualEntry = false

                        //for accessibility, focus the top new element
                        nextTick(() => {
                            document.getElementById(`address[${addressIndex.value}].picklist`).focus()
                        })

                        break
                }
            })
        }
    }


    /* eslint-disable no-unused-vars */
</script>

<style lang="scss" scoped>
    @import '../../node_modules/hd-styles/modules/mixins';

    .address-header h5, .address-header-left h5 {
        margin-top: 10px;
    }

    .address-header-left {
        width: 50%;
        display: inline-block;
    }

    .address-header-right {
        float: right;
        display: inline-block;
        margin-top: 10px;
    }

    .form-row-postcode {
        @include grid-size(calc(24/24));
        text-transform: uppercase;
        padding-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(5/24));
            padding-left: 0;
        }
    }

    .find-address {
        margin-top: 30px;

        @include bp(tablet) {
            margin-top: -9px;
            margin-left: 10px;
        }
    }

    .manual-entry-label, .use-postcode-lookup {
        display: inline-block;
        float: none;
        padding-left: 0;
        padding-top: 20px;
        cursor: pointer;
        text-decoration: underline;
        color: $interactive;
        clear: both;
        width: auto;
        white-space: nowrap;
    }

    .add-address-container {
        background-color: $background-tooltip-hero;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $interactive;
    }

    .address-warning-container, .qas-error-container {
        background-color: $background-warning;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $error;
        display: block;
    }

    .qas-error-container {
        margin-bottom: 40px;
    }

    .summary-header, .summary-main, .summary-main-top-inner, .summary-main-bottom-inner {
        @include grid-context();
        margin-left: 0;
        padding-left: 0;
    }

    .summary-header-left {
        @include grid-size(calc(18/24));
        padding-left: 0;
        font-weight: bold;
    }

    .summary-header-right {
        @include grid-size(calc(6/24));
        text-align-last: right;
    }

    .summary-main-top, .summary-main-bottom {
        @include grid-size(calc(24/24));
        padding-left: 0;
    }

    .summary-main-bottom-inner, .summary-main-top-inner {
        border-bottom: 1px solid $dividers;
        padding-top: 20px;
        padding-bottom: 20px;

        @include bp(tablet) {
            border-left: 1px solid $dividers;
            border-right: 1px solid $dividers;
            border-radius: 5px;
        }
    }

    .summary-main-top-inner {
        border-top: 1px solid $dividers;
        border-bottom: 1px solid $dividers;
        padding-bottom: 20px;
    }

    .summary-left {
        font-weight: bold;
        @include grid-size(calc(12/24));
        padding-left: 20px;
    }

    .summary-right {
        @include grid-size(calc(12/24));
    }

    .summary {
        margin-bottom: 60px;
    }

    .edit-address, .edit-address-desktop, .delete-address, .delete-address-desktop {
        text-decoration: underline;
        color: $interactive;
        cursor: pointer;
    }

    .edit-address, .delete-address {
        @include bp(tablet) {
            display: none;
        }
    }

    .edit-address-desktop, .delete-address-desktop {
        display: none;

        @include bp(tablet) {
            display: inline-block;
        }
    }

    .summary-right-flatNumber {
        margin-right: 4px;
    }

    .summary-right-flatNumber, .summary-right-houseName {
        display: inline-block;
        position: relative;
    }

    .address-warning-left {
        display: inline-block;
        vertical-align: top;
    }

    .address-warning-right {
        display: inline-block;
        width: 80%;
        padding-left: 30px;
    }

    .disabledButton {
        cursor: not-allowed;
        background-color: rgb(229, 229, 229) !important;
    }

    .disabledButton > * {
        pointer-events: none;
    }

    .additional-error {
        color: $error;
    }
</style>

