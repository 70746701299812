<template>
    <div v-if="retailer.content.ContinueShoppingShow == true" class="button-container" >
        <a :href="`${retailer.clickoutLink ? retailer.clickoutLink : ''}`" class="button primary-button-text" @click="logClickout">
            Continue shopping
        </a>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'click-out-link',
        computed: {
            ...mapGetters(['retailer']),
        },
        methods: {
            ...mapActions(['saveSessionEvent']),
            logClickout: function () {
                this.saveSessionEvent({ retailerCode: this.retailer.retailerCode, eventType: 'Click Out Link: ' + this.$route.name });
            },
        },
        created() {
        }
    }
</script>
