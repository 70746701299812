<template>
    <div>

        <div class="address-initial" v-if="address.showPostcodeLookup">
            <PostcodeLookup :address="address" :addressIndex="addressIndex"></PostcodeLookup>
        </div>

        <div class="address-picklist" v-if="address.showPicklist">
            <PickList :address="address" :addressIndex="addressIndex" @updateAddress="updateAddress"></PickList>
        </div>

        <div class="manual-entry" v-if="address.showManualEntry">
            <ManualAddress :address="address" :addressIndex="addressIndex" :maxAddresses="maxAddresses" @deleteAddress="deleteAddress"></ManualAddress>
        </div>

        <div class="summary" v-if="address.showSummary">
            <div class="summary-header form-row">
                <div class="summary-header-left">
                    <h4 v-if="addressIndex == 0">
                        Current UK Address
                    </h4>
                    <h4 v-if="addressIndex > 0">
                        Previous UK Address {{addressIndex}}
                    </h4>
                </div>

                <div class="summary-header-right">
                    <div>
                        <p :id="'address[' + addressIndex + '].editAddressButton'" class="edit-address" @click.prevent="manualEntry" @keydown.enter.prevent="manualEntry" :tabindex="modalShown ? -1 : 0" role="button">Edit</p>
                        <p :id="'address[' + addressIndex + '].editAddressButtonDesktop'" class="edit-address-desktop" @click.prevent="manualEntry" @keydown.enter.prevent="manualEntry" :tabindex="modalShown ? -1 : 0" role="button">Edit these details</p>
                    </div>
                    <div>
                        <p :id="'address[' + addressIndex + '].deleteAddressButton'" class="delete-address" @click.prevent="deleteAddress" @keydown.enter.prevent="deleteAddress" :tabindex="modalShown ? -1 : 0" role="button">Delete</p>
                        <p :id="'address[' + addressIndex + '].deleteAddressButtonDesktop'" class="delete-address-desktop" @click.prevent="deleteAddress" @keydown.enter.prevent="deleteAddress" :tabindex="modalShown ? -1 : 0" role="button">Delete this address</p>
                    </div>
                </div>
            </div>

            <div class="summary-main">
                <div class="summary-main-top" v-if="address.showSummaryTop">
                    <div class="summary-main-top-inner">
                        <div class="summary-left">
                            <p>Address</p>
                        </div>
                        <div class="summary-right">
                            <div class="summary-right-flatNumber" v-if="address.flatNumber">{{address.flatNumber}}</div>
                            <div class="summary-right-houseName" v-if="address.houseName"> {{address.houseName}},</div>
                            <div class="summary-right-houseNumber-street" v-if="address.houseNumber">{{address.houseNumber}} {{address.addressLine1}},</div>
                            <div class="summary-right-townCity" v-if="address.townCity">{{address.townCity}},</div>
                            <div class="summary-right-postcode" v-if="address.postcode">{{formatPostcode(address.postcode)}}</div>
                        </div>
                    </div>
                </div>
                <div class="summary-main-bottom" v-if="address.showSummaryBottom">
                    <div class="summary-main-bottom-inner">
                        <div class="summary-left">
                            Date moved in
                        </div>
                        <div class="summary-right">
                            {{formatDate(address.dateMovedToAddress)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row" v-if="address.showDateMovedToAddress">
            <year-month-input v-model="address.dateMovedToAddress"
                              @updateDateMovedToAddress="updateDateMovedToAddress"
                              :addressIndex="addressIndex"></year-month-input>
        </div>

        <div class="add-address-container" v-if="address.showAddAddress">
            <p v-if="addressIndex == 0">As you've lived at your current address for less than three years, we need your previous address details.</p>
            <p v-if="addressIndex > 0">As you've lived in both your previous and current addresses for less than 3 years, we need you to add details of a second previous address.</p>

            <div class="button-container-inner">
                <button @click.prevent="addAddress"
                        class="button primary-button-text float-left"
                        :tabindex="modalShown ? -1 : 0"
                        type="button"
                        :id="'address[' + addressIndex + '].addAddressButton'">
                    Add another UK address
                </button>
            </div>
        </div>

        <div class="address-warning-container" v-if="address.showAddressWarning">

            <div class="address-warning-left">
                <img class="address-warning-header-image" src="../assets/icon-warning.svg" alt="" />
            </div>

            <div class="address-warning-right">
                <h4 v-if="addressIndex == 0">Previous UK address required</h4>
                <h4 v-if="addressIndex > 0">Second previous UK address required</h4>

                <p v-if="addressIndex == 0">As you've lived at your current address for less than three years, we need your previous address details.</p>
                <p v-if="addressIndex > 0">As you've lived in both your previous and current addresses for less than 3 years, we need you to add details of a second previous address.</p>

                <div class="button-container-inner">
                    <button @click.prevent="addAddress"
                            class="button primary-button-text float-left"
                            :tabindex="modalShown ? -1 : 0"
                            type="button"
                            :id="'address[' + addressIndex + '].addAddressButton'">
                        Add another UK address
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import moment from "moment"
    import YearMonthInput from './YearMonthInput.vue'
    import ManualAddress from './ManualAddress.vue'
    import PostcodeLookup from './PostcodeLookup.vue'
    import PickList from './PickList.vue'
    import { defineProps, ref, toRef, computed, defineEmits, nextTick } from 'vue'
    import { useStore } from 'vuex'
    import { useField } from 'vee-validate' //eslint-disable-line no-unused-vars

    const props = defineProps({
        minMonths: {
            type: Number,
            default: 36
        },
        address: {
            type: Object,
            required: true
        },
        addressIndex: {
            type: Number,
            required: true
        },
        previousAddress: {
            type: Object
        },
        maxAddresses: {
            type: Number,
            required: true
        }
    })

    /* eslint-disable no-unused-vars */
    const emit = defineEmits(['deleteAddressesAfterIndex', 'updateAddressTemplate', 'deleteAddress'])
    const searchingAddress = ref(false)
    const showPostcodeError = ref(false)
    const address = toRef(props.address)
    const addressIndex = toRef(props.addressIndex)
    const maxAddresses = toRef(props.maxAddresses)
    const store = useStore()

    /* eslint-disable no-unused-vars */
    const modalShown = computed(() => store.getters.modalShown)
    const fillAddress = address => store.dispatch('fillAddress', address)

    address.value.id = addressIndex.value

    if (addressIndex.value > 0) {
        const postcodeLookup = document.getElementById(`address[${addressIndex.value}].postcodeLookup`)

        if (postcodeLookup) {
            nextTick(() => postcodeLookup.focus())
        }
    }

    function updateAddress() {
        if (address.value.selectedAddress) {
            fillAddress({
                addressMoniker: address.value.selectedAddress,
                addressIndex: addressIndex.value
            }).then(() => {
                address.value.addressConfirmed = true
                address.value.addressConfirmedWarning = false

                switch (address.value.resultType) {
                    default:
                        //assume error
                        showPostcodeError.value = true
                        address.value.showManualEntry = true
                        address.value.showPicklist = false
                        address.value.showPostcodeLookup = false
                        address.value.showDateMovedToAddress = true
                        break
                    case 0:
                        address.value.showDateMovedToAddress = true
                        address.value.showPicklist = false
                        address.value.showPostcodeLookup = false
                        address.value.showSummary = true
                        address.value.showSummaryTop = true
                        address.value.showSummaryBottom = false

                        //for accessibility, focus the top new element
                        nextTick(() => {
                            document.getElementById(`address[${addressIndex.value}].timeAtAddressMonths`).focus()
                        })

                        break
                }
            })
        }
    }

    function updateDateMovedToAddress(dateMovedToAddress) {
        address.value.dateMovedToAddress = dateMovedToAddress

        const momentDate = moment(address.value.dateMovedToAddress, 'DD-MM-YYYY')
        const currentMomentDate = moment()

        if (monthDiff(momentDate, currentMomentDate) < 36
            && !address.value.showAddressWarning
            && addressIndex.value < maxAddresses.value - 1
            && !address.value.nextAddressAdded) {

            address.value.showAddAddress = true
        }
        else {
            address.value.showAddAddress = false
            address.value.showAddressWarning = false

            emit('deleteAddressesAfterIndex', address.value.id);
            address.value.nextAddressAdded = false
        }
    }

    function monthDiff(d1, d2) {
        let months = (d2.year() - d1.year()) * 12
        months -= d1.month() + 1
        months += d2.month()

        return months <= 0 ? 0 : months
    }

    function formatDate(date) {
        //refactor
        if (!date) return ''

        let formattedDate = moment(date, 'DD-MM-YYYY')

        if (formattedDate.month() + 1 < 10) {
            return '0' + (formattedDate.month() + 1) + '/' + formattedDate.year()
        }
        else {
            return (formattedDate.month() + 1) + '/' + formattedDate.year()
        }
    }

    function manualEntry() {
        address.value.showPostcodeLookup = false
        address.value.showPicklist = false
        address.value.showManualEntry == true ? address.value.showManualEntry = false : address.value.showManualEntry = true;
        address.value.showDateMovedToAddress = true
        address.value.showSummary = false

        //for accessibility, focus the top new element
        nextTick(() => {
            document.getElementById(`address[${addressIndex.value}].houseNumber`).focus()
        })
    }

    async function addAddress() {
        let valid = true

        if (valid) {
            emit('updateAddressTemplate', address.value)
            address.value.showPostcodeLookup = false
            address.value.showPicklist = false
            address.value.showManualEntry = false
            address.value.showDateMovedToAddress = false
            address.value.showAddAddress = false
            address.value.showSummary = true
            address.value.showSummaryTop = true
            address.value.showSummaryBottom = true
            address.value.showAddressWarning = false
            address.value.nextAddressAdded = true
        }
    }

    function deleteAddress() {
        emit('deleteAddress', address.value)
    }

    function deleteAddressesAfterIndex(addressIndex) {
        emit('deleteAddressesAfterIndex'.addressIndex)
    }

    function formatPostcode(postcode) {
        //strip potential space first
        let newPostcode = postcode.replace(' ', '')
        let postcodeFirstPart = ''

        if (newPostcode.length == 6) {
            postcodeFirstPart = newPostcode.substring(0, 3)
        }
        else {
            postcodeFirstPart = newPostcode.substring(0, 4)
        }

        let postcodeSecondPart = newPostcode.slice(newPostcode.length - 3)

        return `${postcodeFirstPart} ${postcodeSecondPart}`
    }
    /* eslint-disable no-unused-vars */
</script>

<style lang="scss" scoped>

    @import '../../node_modules/hd-styles/modules/mixins';

    .address-header h5, .address-header-left h5 {
        margin-top: 10px;
    }

    .address-header-left {
        width: 50%;
        display: inline-block;
    }

    .address-header-right {
        float: right;
        display: inline-block;
        margin-top: 10px;
    }

    .form-row-postcode {
        @include grid-size(calc(24/24));
        text-transform: uppercase;
        padding-left: 0;

        @include bp(tablet) {
            @include grid-size(calc(5/24));
            padding-left: 0;
        }
    }

    .find-address {
        margin-top: 30px;

        @include bp(tablet) {
            margin-top: -9px;
            margin-left: 10px;
        }
    }

    .manual-entry-label, .use-postcode-lookup {
        /*@include grid-size(calc(24/24));*/
        display: inline-block;
        float: none;
        padding-left: 0;
        padding-top: 20px;
        cursor: pointer;
        text-decoration: underline;
        color: $interactive;
        clear: both;
        width: auto;
        white-space: nowrap;
    }

    .add-address-container {
        background-color: $background-tooltip-hero;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $interactive;
    }

    .address-warning-container, .qas-error-container {
        background-color: $background-warning;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $error;
        display: block;
    }

    .qas-error-container {
        margin-bottom: 40px;
    }

    .summary-header, .summary-main, .summary-main-top-inner, .summary-main-bottom-inner {
        @include grid-context();
        margin-left: 0;
        padding-left: 0;
    }

    .summary-header-left {
        @include grid-size(calc(18/24));
        padding-left: 0;
        font-weight: bold;
    }

    .summary-header-right {
        @include grid-size(calc(6/24));
        text-align-last: right;
    }

    .summary-main-top, .summary-main-bottom {
        @include grid-size(calc(24/24));
        padding-left: 0;
    }

    .summary-main-bottom-inner, .summary-main-top-inner {
        border-bottom: 1px solid $dividers;
        padding-top: 20px;
        padding-bottom: 20px;

        @include bp(tablet) {
            border-left: 1px solid $dividers;
            border-right: 1px solid $dividers;
            border-radius: 5px;
        }
    }

    .summary-main-top-inner {
        border-top: 1px solid $dividers;
        border-bottom: 1px solid $dividers;
        padding-bottom: 20px;
    }

    .summary-left {
        font-weight: bold;
        @include grid-size(calc(12/24));
        padding-left: 20px;
    }

    .summary-right {
        @include grid-size(calc(12/24));
    }

    .summary {
        margin-bottom: 60px;
    }    

    .edit-address, .edit-address-desktop, .delete-address, .delete-address-desktop {
        text-decoration: underline;
        color: $interactive;
        cursor: pointer;
    }

    .edit-address, .delete-address {
        @include bp(tablet) {
            display: none;
        }
    }

    .edit-address-desktop, .delete-address-desktop {
        display: none;

        @include bp(tablet) {
            display: inline-block;
        }
    }

    .summary-right-flatNumber {
        margin-right: 4px;
    }

    .summary-right-flatNumber, .summary-right-houseName {
        display: inline-block;
        position: relative;
    }

    .address-warning-left {
        display: inline-block;
        vertical-align: top;
    }

    .address-warning-right {
        display: inline-block;
        width: 80%;
        padding-left: 30px;
    }

    .disabledButton {
        cursor: not-allowed;
        background-color: rgb(229, 229, 229) !important;
    }

    .disabledButton > * {
        pointer-events: none;
    }

    .additional-error {
        color: $error;
    }
</style>
