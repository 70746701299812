import 'core-js/stable'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import vueTimers from 'vue-timers'

import {Tooltip}  from 'bootstrap'
Element.prototype.documentOffsetTop = function () {
    return this.offsetTop + (this.offsetParent ? this.offsetParent.documentOffsetTop() : 0);
};
Element.prototype.scrollIntoViewCenter = function () {
    window.scrollTo(0, this.documentOffsetTop() - (window.innerHeight / 2));
};

Number.prototype.toLocaleFixed = function (n) {
    return this.toLocaleString(undefined, {
        minimumFractionDigits: n,
        maximumFractionDigits: n
    });
};

const app = createApp(App);


app.use(store);
app.use(router);
app.use(vueTimers);

app.directive('tooltip', {
    mounted(el) {
        new Tooltip(el)
    }
});

app.mount('#app');
