<template>
    <div class="modal-background" @click.self="close">
        <div class="modal-container">
            <span class="close-modal" @click.self="close" ref="closeModal" @keyup.enter="close" tabindex="0" aria-label="close dialog" role="button">x</span>

            <div class="modal-container-inner modal-scrolling" id="eligibilityCriteria" aria-labelledby="eligibility-header" aria-describedby="eligibility-content">
                <h2 id="eligibility-header" v-html="retailer.content.EligibilityCriteriaHeader"/>

                <ul id="eligibility-content">
                    <li v-for="criteria in retailer.content.EligibilityCriteria.split('\\n')" v-bind:key="criteria">
                      {{criteria}}
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        computed: {
          ...mapGetters(['retailer'])
        },
        methods: {
            close: function () {
                this.$emit('closeModal');
            }
        },
        mounted() {
            this.$refs.closeModal.focus();
        }
    }

</script>

<style lang="scss" scoped>
</style>
