<template>
    <div class="footer-container">

        <hr aria-hidden="true" />

        <div class="footer-inner small-body-copy" :aria-hidden="modalShown">

            <div class="footer-links-container">
                <div class="footer-column">
                    <span id="footerPrivacyPolicyButton" class="accessibility-link" v-on:click="showPrivacyPolicy" @keydown.enter="showPrivacyPolicy" :tabindex="modalShown ? -1 : 0" role="button">Privacy Policy</span>
                </div>
                <div class="footer-column">
                    <a id="footerCookiesLink" class="accessibility-link" href="https://www.barclayspartnerfinance.com/home/cookies/" target="_blank" :tabindex="this.modalShown ? -1 : 0">Cookie Policy  <img src="../assets/external-link-icon.svg" alt="opens in new window icon"/></a>
                </div>
                <div class="footer-column">
                    <a id="footerContactUsLink" class="accessibility-link" href="https://www.barclayspartnerfinance.com/home/contact-us/" target="_blank" :tabindex="this.modalShown ? -1 : 0">Contact us  <img src="../assets/external-link-icon.svg" alt="opens in new window icon" /></a>
                </div>
                <div class="footer-column">
                    <a id="footerAccessibilityPageLink" class="accessibility-link" href="https://www.barclays.co.uk/accessibility/" target="_blank" :tabindex="this.modalShown ? -1 : 0">Accessibility  <img src="../assets/external-link-icon.svg" alt="opens in new window icon"/></a>
                </div>
            </div>

            <p>
                Barclays Partner Finance is a trading name of Clydesdale Financial Services Limited,
                a member of the Barclays Group. Clydesdale Financial Services Limited is
                authorised and regulated by the Financial Conduct Authority
                (Financial Services Register Number: 311753).
            </p>
            <p>
                Registered in England. Registered No. 2901725. Registered Office: 1 Churchill place, London E14 5HP.
            </p>
        </div>

        <privacy-policy v-if="modals.privacyPolicyShown" @closeModal="closeModal" ></privacy-policy>

    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex'
    import PrivacyPolicy from './PrivacyPolicy.vue'

    export default {
        computed: {
            ...mapGetters(['modals', 'modalShown']),
        },
        components: {
            'privacy-policy': PrivacyPolicy
        },
        methods: {
            ...mapActions(['closeModal']),
            showPrivacyPolicy: function () {
                this.modals.privacyPolicyShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            }
        }
    }

</script>

<style lang="scss" scoped>

    .footer-inner {
        max-width: 90%;
        margin: 0 auto;
        color: $body2;

        @include bp(tablet) {
            max-width: 70%;
        }
    }

    .accessibility-link {
        color: $interactive;
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
        display: block;
    }

    .footer-links-container {
        margin-bottom: 10px;

        @include bp(tablet) {
            @include grid-context();
        }
    }

    .footer-column {
        @include bp(tablet) {
            display: inline-block;
            @include grid-size(calc(1/4));
        }
    }
</style>