<template>
    <div class="cookies-container">
        <div class="cookies-container-inner">
            <div class="cookies-header">
                <h2>Updated cookies policy - you'll see this message only once.</h2>
            </div>
            <div class="cookies-body">
                We use strictly necessary cookies to enable core functionality.
                We do not set any other cookies or similar technologies via this site. 
                For more detailed information about the strictly necessary cookies we use please see our
                <a class="cookies-policy-link" href="https://www.barclays.co.uk/important-information/cookies-policy/" :tabindex="modalShown ? -1 : 0" target="_blank">cookie policy<img src="../assets/external-link-icon.svg" alt="opens in new window icon" /></a>.

                <button @click.prevent="acceptCookies"
                        @keydown.enter.prevent="acceptCookies"
                        class="button secondary-button-text accept-cookies"
                        :tabindex="modalShown ? -1 : 0"
                        type="button"
                        id="acceptCookiesButton">
                    Accept and close
                </button>

            </div>
        </div>
    </div>
</template>


<script>

    import { mapGetters, mapMutations } from 'vuex'

    export default {
        computed: {
            ...mapGetters(['modals', 'modalShown']),
        },
        methods: {
            acceptCookies: function () {
                this.SET_COOKIES_ACCEPTED();
            },
            ...mapMutations(['SET_COOKIES_ACCEPTED'])
        }
    }

</script>

<style lang="scss" scoped>

    .cookies-container {
        background-color: $background-tooltip-hero;
        border-top: 3px solid $dividers;
    }

    .cookies-container-inner {
        width: 90%;
        margin: 0 auto;
        padding: 10px 0;

        @include bp(tablet) {
            width: 70%;
        }
    }

    .cookies-policy-link {
        color: $interactive;
        text-decoration: underline;
        cursor: pointer;
    }

    .accept-cookies
    {
        margin-top: 10px;
        display: block;
    }

</style>