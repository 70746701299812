<template>
    <div class="frequently-asked-questions-container">
        <div class="frequently-asked-questions">
            <h2>Frequently asked questions</h2>

            <faq-item v-for="(faqItem, index) in this.faqItems"
                      :key="index"
                      :headlineText="faqItem.key"
                      :extraInfoText="faqItem.value"></faq-item>

        </div>
    </div>
</template>

<script>

    import FAQItem from '../components/FAQItem.vue'

    export default {
        props: {
            faqItems: {
                type: Array
            }
        },
        components: {
            'faq-item': FAQItem
        }
    }

</script>

<style lang="scss" scoped>

    .frequently-asked-questions-container {
        clear: both;
        background-color: $background2;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-bottom: 20px;
    }

    .frequently-asked-questions {
        width: 90%;
        margin: 30px auto;
        color: $interactive;

        @include bp(tablet) {
            width: 90%;
        }
    }

    .question-container {
        background-color: $background;
        padding: 0px 20px 5px 20px;
        min-height: 60px;
        border-bottom: 1px solid $outline;
        border-left: 1px solid $outline;
        border-right: 1px solid $outline;
    }

        .question-container:last-child {
            border-radius: 0 0 5px 5px;
        }

        .question-container:nth-child(2) {
            border-top: 1px solid $outline;
            border-radius: 5px 5px 0 0;
        }
</style>
