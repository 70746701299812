<template>
  <div>
    <div v-for="exmp in retailer.repExamples" v-bind:key="exmp" class="rep-examples-modal">

      <div class="modal-container-inner modal-scrolling rep-examples-inner">
        <div v-if="exmp.exampleHeader != ''" class="modal-header">
          <h2>{{ exmp.exampleHeader }}</h2>

          <p>{{ subHeading }}</p>
        </div>
        <div v-if="exmp.exampleHeader == ''" class="modal-header">
          <h2>Representative example:</h2>

          <p>{{ subHeading }}</p>
        </div>

        <div>

          <ul class="rep-info-list">
            <li class="rep-info">
              {{ retailer.content.RepExItemPriceName }}<span
                class="rep-info-value">&pound;{{ exmp.itemValue.toLocaleFixed(2) }}</span>
            </li>

            <li class="rep-info">
              {{ retailer.content.RepExDepositName }}<span
                class="rep-info-value">&pound;{{ exmp.deposit.toLocaleFixed(2) }}</span>
            </li>

            <li v-if="exmp.totalAmountOfCredit != 0" class="rep-info">
              {{ retailer.content.RepExTotalAmountOfCreditName }}<span
                class="rep-info-value">&pound;{{ exmp.totalAmountOfCredit.toLocaleFixed(2) }}</span>
            </li>

            <li class="rep-info">
              {{ retailer.content.RepExLoanPeriodName }}<span
                class="rep-info-value">{{ exmp.loanPeriodMonths }} Months</span>
            </li>

            <div v-if="exmp.totalAmountOfCredit != 0">
              <li class="rep-info">
                {{ retailer.content.RepExRateOfInterestName }}<span class="rep-info-value">{{ exmp.rateOfInterest }}% p.a.</span>
              </li>
              <li class="rep-info">
                {{ retailer.content.RepExAPRName }}<span class="rep-info-value">{{ exmp.representativeAPR }}% APR</span>
              </li>
            </div>

            <div v-if="exmp.totalAmountOfCredit == 0">
              <li class="rep-info">
                {{ retailer.content.RepExAPRName }}<span class="rep-info-value">{{ exmp.representativeAPR }}% APR</span>
              </li>
              <li class="rep-info">
                {{ retailer.content.RepExRateOfInterestName }}<span class="rep-info-value">{{ exmp.rateOfInterest }}% p.a.</span>
              </li>
            </div>

            <li class="rep-info">
              {{ exmp.numberOfMonthlyRepayments + retailer.content.RepExMonthlyRepaymentsName }}<span
                class="rep-info-value">&pound;{{ exmp.monthlyRepayments.toLocaleFixed(2) }}</span>
            </li>

            <li class="rep-info">
              {{ retailer.content.RepExTotalAmountPayableName }}<span
                class="rep-info-value">&pound;{{ exmp.totalAmountPayable.toLocaleFixed(2) }}</span>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  props: ['subHeading'],
  computed: {
    ...mapGetters(['modals', 'modalShown', 'retailer']),
  },
  data() {
    return {
      termsAndConditionsShown: false
    };
  },
  methods: {},
  created() {
  }
}
</script>

<style lang="scss" scoped>

  .rep-example-option {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid $outline;
    border-radius: 10px;
    margin-top: 10px;
    margin-right: 10px;
    width: 93%;
    color: $success;
    max-height: 48px;
    flex-basis: 100%;
  
  
    @include bp(tablet) {
      flex-grow: revert;
      flex-basis: 31%;
      max-width: 31%;
    }
  
    &:focus {
      border: 1px solid $warning;
      outline: 0;
    }
  
  }
  
  .selected-option {
    background-color: $background-confirmation;
    border-color: $success;
  
    &::after {
      content: '';
      background-image: url(~@/assets/icon-tick.svg);
      width: 24px;
      height: 24px;
      position: relative;
      display: block;
      background-repeat: no-repeat;
      top: -48px;
      left: 50%;
      background-color: white;
      outline: 0;
  
      @include bp(tablet) {
        left: 48%;
      }
    }
  
  }
  
  .rep-info {
    @include grid-context();
    padding: 0.5rem 1rem;
    border-top: solid grey 1px;
    margin: 0;
  }
  
  .rep-info-list {
    list-style: none;
    padding: 0;
  }
  
  .rep-info-value {
    font-weight: bold;
    float: right;
  }
  
  .rep-example-option-container {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    justify-content: center;
  }
  
  .rep-examples-modal {
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid $outline;
    border-radius: 10px;
    margin-bottom: 20px;
  }

</style>