import { createStore } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import {
    submitApplication,
    fillAddress,
    resolveAddress,
    getConfig,
    saveSessionEvent,
    closeModal,
    generateXSRFToken
} from './actions'
import { vuexCookie } from './cookies'
import { Address, Application, Affordability} from './classes'


const store = createStore({
    state: {
        application: new Application,
        enums: {
            titleEnums: {},
            employmentStatusEnums: {},
            residentialStatusEnums: {},
            resultType: null,
            defaultFAQItems: {}
        },
        affordability: null,
        retailer: {
            phaseID: '',
            name: '',
            clickoutLink: '',
            keepSessionAfterSubmit: '',
            displayAffordabilityQuestions: false,
            displayDependantsQuestion: false,
            displayAffordabilityResults: false,
            repExamples: {},
            term: '',
            maxClientAdvance: null,
            content: {}
        },
        results: {
            maximumRepaymentAmount: null,
            monthlyRepayment: null
        },
        modals: {
            eligibilityCriteriaShown: false,
            termsAndConditionsShown: false,
            privacyPolicyShown: false,
            cookiesAccepted: false,
            repExamplesShown: false,
            lastElement: null
        },
        sessionID: null,
        validationErrors: {}
    },
    mutations: {
        updateField,
        ALL_CONFIG: (state, payload) => {
            state.enums.titleEnums = payload.responseData.lookupEnums.titleEnums;
            state.enums.employmentStatusEnums = payload.responseData.lookupEnums.employmentStatusEnums;
            state.enums.residentialStatusEnums = payload.responseData.lookupEnums.residentialStatusEnums;
            state.retailer.phaseID = payload.responseData.retailerConfiguration.phaseID;
            state.retailer.name = payload.responseData.retailerConfiguration.name;
            state.retailer.averageBorrowingAmount = payload.responseData.retailerConfiguration.averageBorrowingAmount;
            state.retailer.retailerCode = payload.retailerCode;
            state.retailer.clickoutLink = payload.responseData.retailerConfiguration.clickoutLink;
            state.retailer.keepSessionAfterSubmit = payload.responseData.retailerConfiguration.keepSessionAfterSubmit;
            state.retailer.displayAffordabilityQuestions = payload.responseData.retailerConfiguration.displayAffordabilityQuestions;
            state.retailer.displayDependantsQuestion = payload.responseData.retailerConfiguration.displayDependantsQuestion;
            state.retailer.displayAffordabilityResults = payload.responseData.retailerConfiguration.displayAffordabilityResults;
            state.retailer.repExamples = payload.responseData.retailerConfiguration.repExamples;
            state.retailer.term = payload.responseData.retailerConfiguration.term;
            state.retailer.maxClientAdvance = payload.responseData.retailerConfiguration.maxClientAdvance;
            state.enums.defaultFAQItems = payload.responseData.retailerConfiguration.defaultFAQItems;
            for (let i = 0; i < payload.responseData.retailerConfiguration.content.length; i++) {            
                state.retailer.content[payload.responseData.retailerConfiguration.content[i].key] = payload.responseData.retailerConfiguration.content[i].value
            }
        },
        UPDATE_PICKLIST: (state, payload) => {
            let address = state.application.addresses[payload.addressIndex];
            address.picklist = payload.picklist;
            address.resultType = payload.resultType;
        },
        FILL_ADDRESS: (state, payload) => {
            let address = state.application.addresses[payload.addressIndex];
            address.houseNumber = payload.buildingNumber;
            address.houseName = payload.buildingName;
            address.flatNumber = payload.flatNumber;
            address.postcode = payload.postcode.replace(/\s/g, '');
            address.addressLine1 = payload.street;
            address.townCity = payload.townCity;
            address.resultType = payload.resultType;
        },
        UPDATE_ADDRESS_RESULT: (state, payload) => {
            let address = state.application.addresses[payload.addressIndex];
            address.resultType = payload.resultType;
        },
        ADD_ADDRESS: (state) => {
            state.application.addresses.push(new Address);
        },
        REMOVE_ADDRESS: (state, payload) => {
            if (payload.id >= 0) {
                state.application.addresses.splice(payload.id, 1);
            }

            for (let i = 0; i < state.application.addresses.length; i++) {
                state.application.addresses[i].id = i;
            }
        },
        REMOVE_ADDRESSES_AFTER_INDEX: (state, payload) => {
            if (payload.id) {
                state.application.addresses.splice(payload.id, state.application.addresses.length - 1);
            }

            for (let i = 0; i < state.application.addresses.length; i++) {
                state.application.addresses[i].id = i;
            }
        },
        SET_RESULT: (state, payload) => {
            state.enums.resultType = payload.resultType;
            state.retailer.faqItems = payload.faqItems;
            if (payload.repaymentResults != null) {
                state.results.maximumRepaymentAmount = payload.repaymentResults.maximumRepaymentAmount;
                state.results.monthlyRepayment = payload.repaymentResults.monthlyRepayment;
            }
        },
        RESET_APPLICATION: (state) => {
            state.application = new Application;
            state.application.addresses = [];
            state.application.addresses.push(new Address);
            state.affordability = null;
        },
        SESSION_ID: (state, payload) => {
            state.sessionID = payload.sessionID;
        },
        RESET_SESSION_ID: (state) => {
            state.sessionID = null;
        },
        SET_COOKIES_ACCEPTED: (state) => {
            state.modals.cookiesAccepted = true;
        },

        SET_LAST_ELEMENT: (state, payload) => {
            state.modals.lastElement = payload.lastElement;
        },
        CLOSE_MODAL: (state) => {
            state.modals.eligibilityCriteriaShown = false;
            state.modals.termsAndConditionsShown = false;
            state.modals.privacyPolicyShown = false;
            state.modals.repExamplesShown = false;
        },
        INITIALISE_AFFORDABILITY: (state) => {
            if (state.affordability == null) {
                state.affordability = new Affordability;
            }
        },
        SET_VALIDATION_ERRORS: (state, payload) => {
            state.validationErrors = payload.validationErrors;
        },
        CLEAR_VALIDATION_ERRORS: (state) => {
            state.validationErrors = {};
        }
    },
    actions: {
        submitApplication,
        fillAddress,
        resolveAddress,
        getConfig,
        saveSessionEvent,
        closeModal,
        generateXSRFToken
    },
    getters: {
        getField,
        application: state => state.application,
        affordability: state => state.affordability,
        enums: state => state.enums,
        retailer: state => state.retailer,
        sessionID: state => state.sessionID,
        modals: state => state.modals,
        modalShown: state => {
            return state.modals.eligibilityCriteriaShown || state.modals.privacyPolicyShown || state.modals.termsAndConditionsShown || state.modals.repExamplesShown
        },
        results: state => state.results,
        validationErrors: state => state.validationErrors
    },
    plugins: [vuexCookie.plugin]
})

export default store
