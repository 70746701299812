<template>
    <div class="page">
        <div>
            <h2 tabindex="-1" class="main-anchor">Sorry...</h2>
            <p>Unfortunately, based on the information provided, we're unable to offer you finance at this time.</p>
            <p>You can still complete your purchase using another payment method at checkout.</p>

            <div class="what-happens-next-container">
                <div class="what-happens-next-left">
                    <img class="address-warning-header-image" src="../assets/icon-information.svg" alt="" />
                </div>
                <div class="what-happens-next-right">
                    <p>This check has not affected your credit score</p>
                </div>

            </div>

            <click-out-link v-if="retailer.clickoutLink"></click-out-link>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import ClickOut from './ClickOut.vue'

    export default {
        computed: {
            ...mapGetters(['retailer'])
        },
        components: {
            'click-out-link': ClickOut,
        }
    }

</script>

<style lang="scss" scoped>

    .what-happens-next-container {
        background-color: $background-tooltip-hero;
        border: 2px solid $branding;
        border-radius: 5px;
        padding: 12px 15px;
        margin-bottom: 30px;
        margin-top: 20px;
        & p

    {
        margin-bottom: 0;
    }
    }

    .what-happens-next-left {
        display: inline-block;
        vertical-align: top;
    }

    .what-happens-next-right {
        display: inline-block;
        width: 80%;
        padding-left: 10px;
    }
</style>