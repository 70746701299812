<template>
    <form @submit.prevent="handleSubmit">
        <div class="page-container">

            <eligible v-if="this.enums.resultType == 0 || (this.enums.resultType == 5 && this.retailer.phaseID != 3)"
                      :phaseID="this.retailer.phaseID"
                      @showRepExamples="showRepExamples" />

            <eligibleSubjectTo v-if="this.enums.resultType == 5 && (this.retailer.phaseID == 3 || this.retailer.phaseID == 5)"
                      :phaseID="this.retailer.phaseID"
                      @showRepExamples="showRepExamples" />

            <refer v-if="this.enums.resultType == 1"></refer>

            <not-eligible v-if="this.enums.resultType == 2"></not-eligible>

        </div>

        <div class="page page-alt-background">
            <frequently-asked-questions :phaseID="1" :faqItems="this.retailer.faqItems"></frequently-asked-questions>
        </div>
    </form>
</template>

<script>

    import Eligible from '../Eligible.vue'
    import EligibleSubjectTo from '../EligibleSubjectTo.vue'
    import NotEligible from '../NotEligible.vue'
    import Refer from '../Refer.vue'
    import FrequentlyAskedQuestions from '../FAQ.vue'

    import { mapGetters, mapMutations, mapActions } from 'vuex'

    export default {
        methods: {
            ...mapMutations(['RESET_APPLICATION']),
            ...mapActions(['getConfig', 'closeModal']),
            resetApplication() {
                if (!this.retailer.keepSessionAfterSubmit) {
                        this.RESET_APPLICATION();
                    }
            },
            showRepExamples() {
                this.modals.repExamplesShown = true;
                let body = document.getElementsByTagName('body')[0];
                body.classList.add('modal-noscroll');
            }
        },
        computed: {
            ...mapGetters(['enums', 'application', 'retailer', 'modals', 'modalShown'])
        },
        data() {
            return {
                repExamplesShown: false
            };
        },
        components: {
            'eligible': Eligible,
            'eligibleSubjectTo': EligibleSubjectTo,
            'not-eligible': NotEligible,
            'refer': Refer,
            'frequently-asked-questions': FrequentlyAskedQuestions
        },
        created() {
            this.resetApplication();
        }
    }

</script>

<style lang="scss" scoped>

</style>